export const VK_GET_ACCESS_TOKEN_FETCHED = 'vk.accessTokenFetched';
export const VK_GET_ACCESS_TOKEN_FAILED = 'vk.accessTokenFailed';
export const VK_GROUP_INVITE_FETCHED = 'vk.groupInviteFetched';
export const VK_PHONE_FETCHED= 'vk.phoneFetched';
export const VK_EMAIL_FETCHED = 'vk.emailFetched';
export const VK_NAME_FETCHED = 'vk.nameFetched';
export const VK_GEO_FETCHED = 'vk.geoFetched';
export const VK_NOTIFICATION_STATUS_FETCHED = 'vk.notificationsStatusFetched';
export const VK_NOTIFICATION_STATUS_FAILED = 'vk.notificationsStatusFailed';
export const VK_INSETS_FETCHED = 'vk.insetsFetched';
