import * as localForage from "localforage";

export function saveState(name, state) {
    localForage.setItem(name, JSON.stringify(state));
    localStorage.setItem(name, JSON.stringify(state));
    sessionStorage.setItem(name, JSON.stringify(state));
}

export function getState(name) {
    return JSON.parse((localStorage.getItem(name) || sessionStorage.getItem(name) || 'null'));
}
