import React, {Component} from 'react';
import {connect} from 'react-redux';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
class Banner extends Component {

    render() {
        return (
            <Group>
                <div align="center" className="banner">
                    <div className="banner_ny18_collection">
                        <div className="banner_ny18_collection--text">

                        </div>
                    </div>
                </div>
            </Group>
        )
    }

}

export default connect()(Banner);

