
import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import loading from './../services/loading'
import currencyRates from './shop/reducer';
import products from './shop/reducer';
import vk from './vk/reducer';

export default (history) => combineReducers({
    router: connectRouter(history),
    currencyRates: currencyRates,
    vk, products, loading
});
