import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router'

//import registerServiceWorker from './registerServiceWorker';
import registerServiceWorker from './sw';
import { isMobile } from 'react-device-detect';

import {store, history} from './store';
import App from './containers/App';

//store.subscribe(() => {
//    //saveState('listProducts', store.getState());
//});


ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <div className={isMobile !== true ? 'isDesktop' : 'isMobile'}>
                <Route path='/:pageId(basket|product|fabric|size|selected|selectedpunkt)?' component={(props) => <App pageId={props.match.params.pageId}/>}/>
            </div>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);

registerServiceWorker();
