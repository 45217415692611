import React, {Component} from 'react';
import {connect} from 'react-redux';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import ItempProducts from './ItempProducts';
import Banner from './Banner';

class MainPanel extends Component {

    render() {
        return (
                <Panel id={this.props.id}>
                    <PanelHeader>
                        <b style={{  font: '700 Roboto'}}>PRINT BAR</b>
                    </PanelHeader>
                    <Banner/>
                    <ItempProducts/>
                </Panel>
        )
    }

}
export default connect()(MainPanel);

