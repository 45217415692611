import React, {Component} from 'react';
import { platform, IOS } from '@vkontakte/vkui';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import HeaderButton from '@vkontakte/vkui/dist/components/HeaderButton/HeaderButton';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import List from '@vkontakte/vkui/dist/components/List/List';
import {connect} from 'react-redux';
import {goBack} from 'connected-react-router';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import * as currencyItemsProducts from "../store/shop/reducer";
import * as currencyProducts from "../store/shop/actions";

class SelectPickup extends Component {
    getAddressDel(address) {
        this.props.dispatch(currencyProducts.setDelAddress(address));
    }
    isAddress() {
        if (this.props.pickup !== null && this.props.textTypeDeliveryPunkt !== '') {
            return      this.props.dispatch(currencyProducts.setVerDeliveryPunkt(true));
        } else if ((this.props.Mpickup !== 'printbar_pickup' ||  this.props.Mpickup !== 'pochta_pickup' ||  this.props.Mpickup !== 'dpd_pickup' ||  this.props.Mpickup !== 'sdek_pickup') && this.props.textTypeDelivery !== '' && this.props.userAddress !== '') {
            return      this.props.dispatch(currencyProducts.setVerDeliveryPunkt(true));
        }
        return this.props.dispatch(currencyProducts.setVerDeliveryPunkt(false));
    }
    render() {
        const osname = platform();

        return (
                <Panel id={this.props.id}>
                    <PanelHeader
                        left={<HeaderButton onClick={this.navigationBack.bind(this)}>{osname === IOS ?
                            <Icon28ChevronBack/> : <Icon24Back/>}</HeaderButton>}
                    >
                        Пункт самовывоза
                    </PanelHeader>
                    <Group>
                        {this.renderDeliverySelectionMethodPicku()}
                    </Group>
                </Panel>
        );
    }
    renderDeliverySelectionMethodPicku() {
        let {infoDelivery,Mpickup,pickup} = this.props;
        let target     =
                (Mpickup == 'printbar_pickup') ? infoDelivery.printbar.sklad.punkti :
                    (Mpickup == 'pochta_pickup') ? infoDelivery.pochta.sklad.punkti :
                        (Mpickup == 'dpd_pickup') ? infoDelivery.dpd.sklad.punkti :
                            (Mpickup == 'sdek_pickup') ? infoDelivery.sdek.sklad.punkti : null;
        if (pickup !== null) {
            return (
                <List>
                    {target.map((punkt, index) => (
                        <Cell className='selectnew' value={index}
                              target={target[index].adres}
                              onClick={() => {
                                  this.getAddressDel(target[index].adres);
                                  this.navigationBack();
                                  this.isAddress();
                                  this.props.dispatch(currencyProducts.setTextTypePunkt(punkt.adres +' ('+punkt.fullTime+')'));
                              }}>
                            {punkt.adres} ({punkt.fullTime})
                        </Cell>
                    ))}
                </List>
            );
        }
    }
    navigationBack() {
        this.props.dispatch(goBack());
    }
}

function mapStateToProps(state) {
    return {
        userAddress: currencyItemsProducts.getUserAddress(state),

        infoDelivery: currencyItemsProducts.getDeliveryInfo(state),
        infoDelRecomended: currencyItemsProducts.getDeliveryRec(state),
        sumDelivery: currencyItemsProducts.getSumDelivery(state),

        infoDaData: currencyItemsProducts.getDaData(state),

        pickup: currencyItemsProducts.getPickup(state),
        Mpickup: currencyItemsProducts.getMPickup(state),
        dayDel: currencyItemsProducts.getDayDel(state),
    };
}

export default connect(mapStateToProps)(SelectPickup);

