import React, {Component} from 'react';
import {connect} from 'react-redux';
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';
import Input from '@vkontakte/vkui/dist/components/Input/Input';
import Select from '@vkontakte/vkui/dist/components/Select/Select';
import SelectMimicry from '@vkontakte/vkui/dist/components/SelectMimicry/SelectMimicry';
import List from '@vkontakte/vkui/dist/components/List/List';
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Icon24Done from '@vkontakte/icons/dist/24/done';
import { colors } from '@vkontakte/vkui';

import * as currencyItemsProducts from "../../store/shop/reducer";
import * as currencyProducts from "../../store/shop/actions";

import axios from "axios";
import {saveState, getState} from "../../services/storage";
import {push} from "connected-react-router";

class DeliveryForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cart: this.props.cart,
            country:  '',
            inputAddress: null,
            inputCity: getState('city') || '',
            sumDelivery: 0,
            value: '?',
            userAddress: '',
        };
        this.isAddress = this.isAddress.bind(this);
    }
    isAddress(e) {
        const { name, value } = e.currentTarget;
        this.setState({ [name]: value });
        this.props.dispatch(currencyProducts.setUserAddress(value));
        if (this.props.pickup !== null && this.props.textTypeDeliveryPunkt !== '') {
            return      this.props.dispatch(currencyProducts.setVerDeliveryPunkt(true));
        } else if ((this.props.Mpickup !== 'printbar_pickup' ||  this.props.Mpickup !== 'pochta_pickup' ||  this.props.Mpickup !== 'dpd_pickup' ||  this.props.Mpickup !== 'sdek_pickup') && this.props.textTypeDelivery !== '' && value !== '') {
            return      this.props.dispatch(currencyProducts.setVerDeliveryPunkt(true));
        }
        return      this.props.dispatch(currencyProducts.setVerDeliveryPunkt(false));
    }
    //handleChange = (event) => {
    //    this.setState({ value: event.target.value });
    //};
    onTypeDelivery(value) {
        //console.log(value);
            let {infoDelivery} = this.props;
        if (value != '') {
            let target         =
                    (value == 'sdek') ? 'sdek' :
                        (value == 'sdek_pickup') ? 'sdek' :
                            (value == 'dpd') ? 'dpd' :
                                (value == 'dpd_pickup') ? 'dpd' :
                                    (value == 'pochta') ? "pochta" :
                                        (value == 'pochta_pickup') ? "pochta" :
                                            (value == 'printbar') ? "printbar" :
                                                (value == 'printbar_pickup') ? "printbar" : null;
            let targetDep      =
                    (value == 'sdek_pickup') ? infoDelivery[target].sklad.price[0] :
                        (value == 'dpd_pickup') ? infoDelivery[target].sklad.price[0] :
                            (value == 'pochta_pickup') ? infoDelivery[target].sklad.price[0] :
                                (value == 'printbar_pickup') ? infoDelivery[target].sklad.price[0] : infoDelivery[target].door.price[0];
            let deliverType      =
                    (value == 'sdek_pickup') ? infoDelivery[target].sklad.deliver_type[0] :
                        (value == 'dpd_pickup') ? infoDelivery[target].sklad.deliver_type[0] :
                            (value == 'pochta_pickup') ? infoDelivery[target].sklad.deliver_type[0] :
                                (value == 'printbar_pickup') ? infoDelivery[target].sklad.deliver_type[0] : infoDelivery[target].door.deliver_type[0];
            let day      =
                    (value == 'sdek_pickup') ? infoDelivery[target].sklad.days :
                        (value == 'dpd_pickup') ? infoDelivery[target].sklad.days :
                            (value == 'pochta_pickup') ? infoDelivery[target].sklad.days :
                                (value == 'printbar_pickup') ? infoDelivery[target].days : infoDelivery[target].door.days;
            //let deyPlus = parseInt(day + 3);
            let targetPickup        =
                    (value == 'sdek_pickup') ? 'sdek_pickup' :
                        (value == 'dpd_pickup') ? 'dpd_pickup' :
                            (value == 'pochta_pickup') ? "pochta_pickup" :
                                (value == 'printbar_pickup') ? "printbar_pickup" : null;

            this.props.dispatch(currencyProducts.setDeliverySum(
                (value == '' ? 0 : targetDep))
            );
            this.props.dispatch(currencyProducts.setDeliveryType(
                (value == '' ? 1 : deliverType))
            );
            this.props.dispatch(currencyProducts.setMPickup(targetPickup));
            saveState('Mpickup', value);
            {day == undefined ? this.props.dispatch(currencyProducts.setDayDel("1-2")) : this.props.dispatch(currencyProducts.setDayDel(day))}
        }
        else {
            this.props.dispatch(currencyProducts.setMPickup(null));
            saveState('Mpickup', null);
        }
    }
    onTypeDeliveryMethod(value) {
    //console.log(value);
        if (value != '') {
            let targetMethod = (value == 'sdek_pickup') ? 'sklad' :
                (value == 'dpd_pickup') ? 'sklad' :
                    (value == 'pochta_pickup') ? 'sklad' :
                        (value == 'printbar_pickup') ? 'sklad' : null;

            this.props.dispatch(currencyProducts.setPickup(targetMethod));
            saveState('pickup', value);
        }
        else {
            this.props.dispatch(currencyProducts.setPickup(null));
            saveState('pickup', null);
        }
    }
    getDadata(data) {
    let self = this;
    const API_KEY = 'ead15382139f22b3155ec8f27efb2bab2c78f7f3';
    axios.post(
        'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?5', // url
        { query: data , count: 10}, // data
        {
            headers: {
                'Content-Type':  'application/json',
                'Accept':        'application/json',
                'Authorization': 'Token ' + API_KEY
            },
        }
    )
        .then(function (response) {
            //console.log(response.data.suggestions);
            self.props.dispatch(currencyProducts.setDaData(response.data.suggestions));
            //self.getAddressSuggestions(response.data.suggestions[0]);

        })
        .catch(function (error) {
            console.log(error);
        });
}
    getAddressSuggestions(suggestion) {
        //console.log(suggestion.value);
        let deliveryAdress = suggestion.value;
        let city       = suggestion.data.city === null ? suggestion.data.region : suggestion.data.city;
        let areaType       = suggestion.data.area_with_type === null ? '' : ', ' + suggestion.dataarea_with_type;
        let regionType = suggestion.data.region_with_type === null ? '' : ', ' + suggestion.data.region_with_type;
        let cityType   = suggestion.data.settlement_with_type === null ? '' : ', ' + suggestion.data.settlement_with_type;
        //
        //if (suggestion.data.settlement!=null) {
        //    addressValue += suggestion.data.settlement + ', ';
        //}
        //if (suggestion.data.city!=null) {
        //    addressValue += suggestion.data.city + ', ';
        //}
        //if (suggestion.data.region_with_type!=null) {
        //    addressValue += suggestion.data.region_with_type + ', ';
        //}
        //if (suggestion.data.area_with_type!=null) {
        //    addressValue += suggestion.data.area_with_type + ', ';
        //}
        //addressValue = addressValue.slice(0,-2);
        this.getInfoDelivery(city + regionType);
        this.setState({inputAddress: deliveryAdress});
        //this.props.dispatch(currencyProducts.setUserAddress(deliveryAdress));
    };
    // Получение данных о доставке
    getInfoDelivery(data) {
        //console.log(data);
        //let self = this;
        const url  = "https://printbar.ru/api/internal/get/delivery/";
        const key  = '3f7cdb1eb04b643852633bde6f02611ef0f4d4c8'; //ключ запрос
        const login = 'lootboxrussia@gmail.com'; // логин запроса
        const type = 'online'; // тип запрос
        const country = 'Россия'; // страна (по умолчанию - Россия)

        let city = data == null ? 'Москва': data; // город доставки

        let urlGet = `${url}?key=${key}&login=${login}&type=${type}&country=${country}&city=${city}`;

        //Новая Москва Нижегородская обл Лукояновский р-н
        axios.get(urlGet)
            .then(response => {
                this.props.dispatch(currencyProducts.setDelivery(response.data));
            });
    };

    //getPriceDelivery(setprice) {
    //    let {infoDelivery} = this.props;
    //    let setPrice = setprice;
    //    let setPrice1 = infoDelivery[setPrice];
    //    console.log()
    //
    //}
    //getPriceDel(data) {
    //    console.log(data)
    //    this.props.dispatch(currencyProducts.setDeliverySum(data));
    //}
    //getAddressDel(address) {
    //    //console.log(address)
    //    this.props.dispatch(currencyProducts.setDelAddress(address));
    //}
    ruDate(plus,second) {
        let month = 'января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря'.split(','),
            start= new Date(),
            end = new Date();
        if (second) {
            start.setDate(start.getDate());
            end.setDate(end.getDate());
        }
        if (plus.split('-').length==2 && plus.indexOf('-')!=0) {
            start.setDate(start.getDate() + 3 + parseInt(plus.split('-')[0]));
            end.setDate(end.getDate() + 3 + parseInt(plus.split('-')[1]));
            if (second) {
                if (start.getDay() == 6) start.setDate(start.getDate() + 2);
                if (start.getDay() == 0) start.setDate(start.getDate() + 1);
                if (end.getDay() == 6) end.setDate(end.getDate() + 2);
                if (end.getDay() == 0) end.setDate(end.getDate() + 1);
                if (start.getDate() == 8 && start.getMonth() == 4) start.setDate(start.getDate() + 2);
                if (start.getDate() == 9 && start.getMonth() == 4) start.setDate(start.getDate() + 1);
                if (end.getDate() == 8 && end.getMonth() == 4) end.setDate(end.getDate() + 2);
                if (end.getDate() == 9 && end.getMonth() == 4) end.setDate(end.getDate() + 1);
            }

            if (start > new Date(2017,11,31) && start <= new Date(2018,0,2,23,59,59)) {
                start = new Date(2018,0,3);
                end.setDate(start.getDate() + 3 + parseInt(plus.split('-')[1]));
            }
            if (start >= new Date(2018,0,7) && start < new Date(2018,0,8)) {
                start = new Date(2018,0,8);
                end.setDate(start.getDate()  + 3+ parseInt(plus.split('-')[1]));
            }
            if (end > new Date(2017,11,31) && end <= new Date(2018,0,2,23,59,59)) {
                end = new Date(2018,0,3);
            }
            if (end >= new Date(2018,0,7) && end < new Date(2018,0,8)) {
                end = new Date(2018,0,8);
            }

            //if ($('[name="fast-city"]').val() != 'Москва') {
            //    if (start >= new Date(2018,0,3) && start < new Date(2018,0,4)) {
            //        start = new Date(2018,0,4);
            //        end.setDate(start.getDate() + parseInt(plus.split('-')[1]));
            //    }
            //    if (start >= new Date(2018,0,5) && start < new Date(2018,0,6)) {
            //        start = new Date(2018,0,6);
            //        end.setDate(start.getDate() + parseInt(plus.split('-')[1]));
            //    }
            //    if (end >= new Date(2018,0,3) && end < new Date(2018,0,4)) {
            //        end = new Date(2018,0,4);
            //    }
            //    if (end >= new Date(2018,0,5) && end < new Date(2018,0,6)) {
            //        end = new Date(2018,0,6);
            //    }
            //}
            // Добавил один день к датам
            return start.getDate()+' '+month[start.getMonth()]+'-'+ (end.getDate()) +' '+month[end.getMonth()];
        } else {
            plus = parseInt(plus);
            start.setDate(start.getDate() + plus);
            //if (second && !($('.iti-flag').hasClass('ru') && $('[name="fast-city"]').val() == 'Москва')) {
            //    if (start.getDay() == 6) start.setDate(start.getDate() + 2);
            //    if (start.getDay() == 0) start.setDate(start.getDate() + 1);
            //    if (start.getDate() == 8 && start.getMonth() == 4) start.setDate(start.getDate() + 2);
            //    if (start.getDate() == 9 && start.getMonth() == 4) start.setDate(start.getDate() + 1);
            //
            //}

            if (start > new Date(2017,11,31) && start <= new Date(2018,0,2)) {
                start = new Date(2018,0,3);
            }
            if (start >= new Date(2018,0,7) && start < new Date(2018,0,8)) {
                start = new Date(2018,0,8);
            }
            //if ($('[name="fast-city"]').val() != 'Москва') {
            //    if (start >= new Date(2018, 0, 3) && start < new Date(2018, 0, 4)) {
            //        start = new Date(2018, 0, 4);
            //    }
            //    if (start >= new Date(2018, 0, 5) && start < new Date(2018, 0, 6)) {
            //        start = new Date(2018, 0, 6);
            //    }
            //}

            // Добавил один день к датам
            return (start.getDate()) +' '+month[start.getMonth()];
        }
    }
    render() {
        let {pickup} = this.props;
        let city = this.state.inputCity;
        return (
                <FormLayout>
                    {/*<Input top="Почтовый индекс" value={this.ruDate(this.props.dayDel)}/>*/}
                    {/*<Input top="Город" placeholder="Москва"/>*/}
                    <Input top="Город" defaultValue={city} value={city} onChange={(e) => {
                        this.getDadata(e.target.value);
                        //this.setState({inputAddress: null, Mpickup: null, pickup: null});
                        this.setState({inputCity: null, inputAddress: null});

                        this.props.dispatch(currencyProducts.setPickup(null));
                        this.props.dispatch(currencyProducts.setMPickup(null));

                        //this.props.dispatch(currencyProducts.setUserAddress(e.target.value));
                    }}/>
                    {this.renderDaDataSelect()}
                    {pickup == null ?
                        <Input top="Адрес доставки"
                               type="address"
                               name="userAddress"

                               value={this.state.userAddress}
                               defaultValue={this.props.userAddress}
                                                onChange={this.isAddress}
                    /> : null
                        }
                    {this.renderDeliveryMethod()}
                    {this.renderDeliveryMethodPickup()}
                </FormLayout>
        )
    }
    renderDaDataSelect() {
        let self = this;
        const dadataSuggestions     = {
            position: 'absolute',
            zIndex: 10,
            top: '100%',
            left: 0,
            right: 0,
            marginBottom: 50,
            border: 'solid .5px rgba(0, 0, 0, 0.12)',
            border: 'solid .5px var(--field_border)',
        };
        const dadataSuggestion = {
            fontSize: 15,
            padding: '0 10px',
            cursor: 'pointer',
            position: 'relative',
            zIndex: 12,
        };
        const dadataSuggestionNote = {
            fontSize: 14,
            pointerEvents: 'none',
            color: '#828282 !important',
            padding: '10px 10px 5px 10px',
        };
        if (this.props.infoDaData != null && this.props.infoDaData != '') {
            return (
            <Group style={{position: 'relative'}}>
                <List style={dadataSuggestions}>
                    <Div style={dadataSuggestionNote}>Выберите вариант или продолжите ввод</Div>
                    {this.props.infoDaData.map((item,index) => (
                        <Cell
                            key={index}
                            style={dadataSuggestion}
                            onClick={() =>{
                                self.getAddressSuggestions(item);
                                self.setState({country: item.data.fias_id, inputCity: item.value});
                                self.props.dispatch(currencyProducts.setDaData());
                                saveState('city', item.value);
                                this.props.dispatch(currencyProducts.setTextTypeDelivery(''));
                                this.props.dispatch(currencyProducts.setTextTypePunkt(''));
                            }}
                            asideContent={self.state.country === item.data.fias_id ? <Icon24Done fill={colors.blue_300} /> : null}
                        >
                            {item.value}
                        </Cell>
                    ))}
                </List>
            </Group>
            )
        }
    }
    renderDeliveryMethod() {
        let {infoDelivery} = this.props;
        if (infoDelivery !== undefined) {
            return (
                <SelectMimicry className='devItems'
                top="Способ доставки" placeholder="Выберите способ доставки" bottom={'Мы произведем ваш заказ после подтверждения за 72 часов ('+this.ruDate("3") +'). Доставка заказа займет '+this.props.dayDel+' ('+this.ruDate(this.props.dayDel) +').'}
                onClick={() => {
                    this.props.dispatch(push('/selected'));}}
                     >{this.props.textTypeDelivery}</SelectMimicry>

            );
        }
    }
    renderDeliveryMethodPickup() {
        let {pickup} = this.props;

        if (pickup !== null) {
            this.props.dispatch(currencyProducts.setUserAddress(''));
            return (
                <SelectMimicry className='pickupItems'
                    top="Пункты самовывоза" placeholder="Выберите пункт самовывоза"
                    onClick={() => {
                        this.props.dispatch(push('/selectedpunkt'));}}
                >{this.props.textTypeDeliveryPunkt}</SelectMimicry>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        cart:currencyItemsProducts.getItemCart(state),
        userAddress: currencyItemsProducts.getUserAddress(state),

        infoDelivery: currencyItemsProducts.getDeliveryInfo(state),
        infoDelRecomended: currencyItemsProducts.getDeliveryRec(state),
        sumDelivery: currencyItemsProducts.getSumDelivery(state),

        infoDaData: currencyItemsProducts.getDaData(state),

        pickup: currencyItemsProducts.getPickup(state),
        Mpickup: currencyItemsProducts.getMPickup(state),
        dayDel: currencyItemsProducts.getDayDel(state),

        textTypeDelivery: currencyItemsProducts.getTextTypeDelivery(state),
        textTypeDeliveryPunkt: currencyItemsProducts.getTextTypeDeliveryPunkt(state),

    };
}

export default connect(mapStateToProps)(DeliveryForm);
