import React, {Component} from 'react';
import { platform, IOS } from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import HeaderButton from '@vkontakte/vkui/dist/components/HeaderButton/HeaderButton';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import {connect} from 'react-redux';
import {goBack} from 'connected-react-router';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import * as currencyItemsProducts from "../store/shop/reducer";
import PinchToZoom from 'react-pinch-and-zoom';

class FabricSize extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemData : this.props.items,
        };
    }
    render() {
        const osname = platform();
        const itemData = this.state.itemData;
        let itemType = (itemData !== undefined ? (itemData.type) : null);
        let itemSex = (itemData !== undefined ? (itemData.sex) : null);
        return (

            <Panel id={this.props.id}>
                <PanelHeader
                    left={<HeaderButton onClick={this.navigationBack.bind(this)}>{osname === IOS ?
                        <Icon28ChevronBack/> : <Icon24Back/>}</HeaderButton>}
                >
                    Размерная сетка
                </PanelHeader>
                {this.infoSize(itemType, itemSex)}
            </Panel>
        );
    }

    infoSize(type, sex) {
        if (type === 'Майки') {
            return (
                <Group title="Майка">
                    {sex === "Мужчинам" ?
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/men_may.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                        :
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                                <img style={{
                                    width: 'auto',
                                    maxWidth: '100%'
                                }} src="https://printbar.ru/images/themes/wom_may.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                    }
                </Group>
            );
        } else if (type === 'Свитшоты') {
            return (
                <Group title="Свитшот">
                    {sex === "Мужчинам" ?
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/men_swi.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                        :
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/wom_swi.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                    }
                </Group>
            );
        } else if (type === 'Футболки') {
            return (
                <Group title="Футболка">
                    {sex === "Мужчинам" ?
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/men_fut.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                        :
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/wom_fut.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                    }
                </Group>
            );
        } else if (type === 'Худи') {
            return (
                <Group title="Худи">
                    {sex === "Мужчинам" ?
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/men_hud.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                        :
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/wom_hud.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                    }
                </Group>
            );
        } else if (type === 'Поло') {
            return (
                <Group title="Поло">
                    {sex === "Мужчинам" ?
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/men_pol.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                        :
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/wom_pol.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                    }
                </Group>
            );
        } else if (type === 'Майки борцовки') {
            return (
                <Group title="Майка-борцовка">
                    {sex === "Мужчинам" ?
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/men_mayb.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                        :
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/wom_mayb.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                    }
                </Group>
            );
        } else if (type === 'Лонгсливы') {
            return (
                <Group title="Лонгслив">
                    {sex === "Мужчинам" ?
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/men_lon.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                        :
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/wom_lon.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                    }
                </Group>
            );
        } else if (type === 'Бомберы') {
            return (
                <Group title="Бомбер">
                    {sex === "Мужчинам" ?
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/men_bmb.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                        :
                        <Div style={{textAlign: 'center'}}>
                            <PinchToZoom>
                            <img style={{
                                width: 'auto',
                                maxWidth: '100%'
                            }} src="https://printbar.ru/images/themes/wom_bmb.jpg" alt=""/>
                            </PinchToZoom>
                        </Div>
                    }
                </Group>
            );
        } else if (type === 'Шорты женские') {
            return (
                <Group title="Шорты">
                    <Div style={{textAlign: 'center'}}>
                        <PinchToZoom>
                        <img style={{
                            width: 'auto',
                            maxWidth: '100%'
                        }} src="https://printbar.ru/images/themes/wom_shj.jpg" alt=""/>
                        </PinchToZoom>
                    </Div>
                </Group>
            );
        } else if (type === 'Шорты мужские') {
            return (
                <Group title="Шорты">
                    <Div style={{textAlign: 'center'}}>
                        <PinchToZoom>
                        <img style={{
                            width: 'auto',
                            maxWidth: '100%'
                        }} src="https://printbar.ru/images/themes/men_shm.jpg" alt=""/>
                        </PinchToZoom>
                    </Div>
                </Group>
            );
        }
    }
    navigationBack() {
        this.props.dispatch(goBack());
    }
}

function mapStateToProps(state) {
    return {
        items:currencyItemsProducts.getItemData(state),
        product: currencyItemsProducts.getItemsProducts(state)
    };
}

export default connect(mapStateToProps)(FabricSize);