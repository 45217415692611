import React, {Component} from 'react';
import {connect} from 'react-redux';

import ConfigProvider from '@vkontakte/vkui/dist/components/ConfigProvider/ConfigProvider';
import Root from '@vkontakte/vkui/dist/components/Root/Root';
import Epic from '@vkontakte/vkui/dist/components/Epic/Epic';
import Tabbar from '@vkontakte/vkui/dist/components/Tabbar/Tabbar';
import TabbarItem from '@vkontakte/vkui/dist/components/TabbarItem/TabbarItem';
import Link from '@vkontakte/vkui/dist/components/Link/Link';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import View from '@vkontakte/vkui/dist/components/View/View';


import '@vkontakte/vkui/dist/vkui.css';

import '../style/custom-reset.css';
import '../style/normalize.css';
import '../style/primary.css';
import '../style/banner.css';


import * as vkSelectors from '../store/vk/reducer';
import * as vkActions from '../store/vk/actions';

import MainPanel from './Main';
import CartPanel from './Cart';
import ItemPanel from './ItemInfo';
import FabricPanel from './InfoFabric';
import SizePanel from './InfoSize';
import SelectPanel from './SelectMod';
import SelectPanelPunkt from './SelectPickupItems';

import Icon24Home from '@vkontakte/icons/dist/24/home';
import Icon24Market from '@vkontakte/icons/dist/24/market';

import * as currencyProducts from '../store/shop/actions';
import * as currencyItemsProducts from "../store/shop/reducer";
import {push} from "connected-react-router";

import {isMobile} from "react-device-detect";
import Icon28Users from "@vkontakte/icons/dist/28/users";

import vkuiConnect from '@vkontakte/vkui-connect';

////
// https://www.npmjs.com/package/react-gtm-module - работа с Google Tag Manager
//import TagManager from 'react-gtm-module'
//
//const tagManagerArgs = {
//    gtmId: 'GTM-000000'
//};

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePanel: 'mainPanel',
            activeStory: 'mainPanel',
            history: ['mainPanel']
        };
        this.onStoryChange = this.onStoryChange.bind(this);
    }

    goBack = () => {
        const history = [...this.state.history];
        history.pop();
        const activePanel = history[history.length - 1];
        if (activePanel === 'mainPanel') {
            vkuiConnect.send('VKWebAppDisableSwipeBack');
        }
        this.setState({ history, activePanel });
    }
    goForward = (activePanel) => {
        const history = [...this.state.history];
        history.push(activePanel);
        if (this.state.activePanel === 'mainPanel') {
            vkuiConnect.send('VKWebAppEnableSwipeBack');
        }
        this.setState({ history, activePanel });
    }
    onStoryChange (e) {
        this.setState({ activeStory: e.currentTarget.dataset.story });
    }

    componentWillMount() {
        this.props.dispatch(vkActions.initApp());
        this.props.dispatch(vkActions.fetchAccessToken());
        this.props.dispatch(currencyProducts.fetchItemListProducts());
    }
    render() {
        let {cart} = this.props;
        let activePanel =
                (this.props.pageId === 'basket') ? 'cartPanel' :
                    (this.props.pageId === 'product') ? 'itemPanel' :
                        (this.props.pageId === 'fabric') ? 'fabricPanel' :
                            (this.props.pageId === 'size') ? 'sizeShart' :
                                (this.props.pageId === 'selected') ? 'selectPanel' :
                                    (this.props.pageId === 'selectedpunkt') ? 'selectPanelPunkt' : 'mainPanel';


        return (
            <ConfigProvider insets={this.props.insets} isWebView={true}>
                <style>
                    {isMobile !== true ?
                   " @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=cyrillic');"
                    : ''}

                </style>
                <Epic activeStory={activePanel} tabbar={
                    <Tabbar>
                        <TabbarItem
                            onClick={(e) => {
                                this.openMain();
                                this.onStoryChange(e);
                            }}
                            selected={this.state.activeStory === 'mainPanel'}
                            data-story="mainPanel"
                            text="Главная"
                        >
                            <Icon24Home/>
                        </TabbarItem>
                        <TabbarItem
                            selected={this.state.activeStory === 'mainGroup'}
                            data-story="mainGroup"
                            text="Сообщество">
                            <Link href="https://vk.com/printbar" target="_blank">
                                <Icon28Users style={{color:'#99a2ad'}}/>
                            </Link>
                        </TabbarItem>
                        <TabbarItem
                            text="PRINT BAR">
                            <Link href="https://printbar.ru" target="_blank">
                                <Avatar src="pb-logo.png" size={28}/>
                            </Link>
                        </TabbarItem>
                        <TabbarItem
                            onClick={(e) => {
                                this.onStoryChange(e);
                                this.openCart();
                            }}
                            selected={this.state.activeStory === 'cartPanel'}
                            data-story="cartPanel"
                            text="Корзина"
                            label={cart.length !== 0 ? cart.length : null}
                        ><Icon24Market/>
                        </TabbarItem>
                    </Tabbar>
                }>
                </Epic>
                <Root activeView="mainView">
                    <View popout={this.props.product === undefined ?
                        this.props.popout : this.props.popout}
                          id="mainView"
                          activePanel={activePanel}
                          onSwipeBack={this.goBack}
                          history={this.state.history}>
                        <MainPanel id="mainPanel" accessToken={this.props.accessToken}/>
                        <ItemPanel id="itemPanel" accessToken={this.props.accessToken}/>
                        <FabricPanel id="fabricPanel" accessToken={this.props.accessToken}/>
                        <SizePanel id="sizeShart" accessToken={this.props.accessToken}/>
                        <CartPanel id="cartPanel" accessToken={this.props.accessToken}/>
                        <SelectPanel id="selectPanel" accessToken={this.props.accessToken}/>
                        <SelectPanelPunkt id="selectPanelPunkt" accessToken={this.props.accessToken}/>
                    </View>
                </Root>
            </ConfigProvider>
        );
    }
    openMain () {
        this.props.dispatch(push(''));
    }
    openCart() {
        this.props.dispatch(push('/basket'));
    }
}

function mapStateToProps(state) {
    return {
        accessToken: vkSelectors.getAccessToken(state),
        insets: vkSelectors.getInsets(state),
        cart:currencyItemsProducts.getItemCart(state),

        product: currencyItemsProducts.getItemsProducts(state),
        popout: currencyItemsProducts.getPopout(state),
    };
}

export default connect(mapStateToProps)(App);
