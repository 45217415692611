import Immutable from 'seamless-immutable';
import { saveState, getState } from '../../services/storage';
import * as types from './actionTypes';

const initialState = Immutable({
    currencyRates: undefined,
    //setItemProduct: undefined,
    //temp
    cart: getState('cart') || [],
    selectSizes: getState('selectSizes') || [],
    selectType: getState('selectType') || [],
    selectQty: getState('selectQty') || [],
    del: undefined,
    popout: null,
    orderId: null,
    userName:  getState('userName') || '',
    userEmail:  getState('userEmail') || '',
    userPhone:  getState('userPhone') || '',
    userAddress: getState('userAddress') || '',
    Mpickup: null,
    pickup: null,
    dayDel: '1-2',
    textTypeDelivery: '',
    textTypeDeliveryPunkt: '',
    verifiedDeliveryPunkt: false,
});

export default function reduce(state = initialState, action) {
    switch (action.type) {
        case types.PRODUCTS_FETCHED:
            return {
                ...state,
                product: action.product
            };
        case types.SET_ITEM_DATA:
            return {
                ...state,
                items: action.items
            };
        case types.ADD_TO_CART:
            return saveState('cart', [...state.cart, action.cart]), {
                ...state,
                cart: [...state.cart, action.cart]
            };
        case types.ADD_TO_SIZE:
            return saveState('selectSizes', [...state.selectSizes, action.selectSizes]), {
                ...state,
                selectSizes: [...state.selectSizes, action.selectSizes]
            };
        case types.ADD_TO_TYPE:
            return saveState('selectType', [...state.selectType, action.selectType]), {
                ...state,
                selectType: [...state.selectType, action.selectType]
            };
        case types.ADD_TO_QTY:
            return saveState('selectQty', [...state.selectQty, action.selectQty]), {
                ...state,
                selectQty: [...state.selectQty, action.selectQty]
            };
        case types.DEL_TO_CART:
            return saveState('cart', [...state.cart.slice(0, action.del), ...state.cart.slice(action.del + 1)]), saveState('selectSizes', [...state.selectSizes.slice(0, action.del), ...state.selectSizes.slice(action.del + 1)]), saveState('selectType', [...state.selectType.slice(0, action.del), ...state.selectType.slice(action.del + 1)]), saveState('selectQty', [...state.selectQty.slice(0, action.del), ...state.selectQty.slice(action.del + 1)]), {
                ...state,
                cart: [...state.cart.slice(0, action.del), ...state.cart.slice(action.del + 1)],
                selectSizes: [...state.selectSizes.slice(0, action.del), ...state.selectSizes.slice(action.del + 1)],
                selectType: [...state.selectType.slice(0, action.del), ...state.selectType.slice(action.del + 1)],
                selectQty: [...state.selectQty.slice(0, action.del), ...state.selectQty.slice(action.del + 1)]
            };
        case types.SET_POPOUT:
            return {
                ...state,
                popout: action.popout
            };
        case types.SET_ORDER_ID:
            return {
                ...state,
                orderId: action.orderId
            };
        case types.SET_DATA_SEND_ORDER:
            return {
                ...state,
                dataSendOrder: action.dataSendOrder
            };
        case types.SET_DATA_SEND_VKPAY:
            return {
                ...state,
                VKPAYDataSend: action.VKPAYDataSend
            };
        case types.SET_USER_NAME:
            return saveState('userName', action.userName),{
                ...state,
                userName: action.userName
            };
        case types.SET_USER_EMAIL:
            return saveState('userEmail', action.userEmail),{
                ...state,
                userEmail: action.userEmail
            };
        case types.SET_USER_PHONE:
            return saveState('userPhone', action.userPhone),{
                ...state,
                userPhone: action.userPhone
            };
        case types.SET_USER_ADDRESS:
            return saveState('userAddress', action.userAddress),{
                ...state,
                userAddress: action.userAddress
            };
        case types.SET_DEL_ADDRESS:
            return saveState('delAddress', action.delAddress),{
                ...state,
                delAddress: action.delAddress
            };
        case types.SET_POINT_DELIVERY:
            return {
                ...state,
                infoDelivery: action.infoDelivery,
                infoDelRecomended: action.infoDelRecomended
            };
        case types.SET_SUM_DELIVERY:
            return {
                ...state,
                sumDelivery: action.sumDelivery,
            };
        case types.SET_DELIVERY_TYPE:
            return {
                ...state,
                typeDelivery: action.typeDelivery,
            };
        //    Доставка
        case types.SET_PICKUP:
            return {
                ...state,
                pickup: action.pickup,
            };
        case types.SET_MPICKUP:
            return {
                ...state,
                Mpickup: action.Mpickup,
            };
        case types.SET_DAY_DEL:
            return {
                ...state,
                dayDel: action.dayDel,
            };
        //    Fake
        case types.SET_TEXT_TYPE_DELIVERY:
            return {
                ...state,
                textTypeDelivery: action.textTypeDelivery,
            };
        case types.SET_TEXT_TYPE_PUNKT:
            return {
                ...state,
                textTypeDeliveryPunkt: action.textTypeDeliveryPunkt,
            };
        case types.SET_VET_DEL_PUNKT:
            return {
                ...state,
                verifiedDeliveryPunkt: action.verifiedDeliveryPunkt,
            };
        case types.SET_DA_DATA:
            return {
                ...state,
                infoDaData: action.infoDaData,
            };
        default:
            return state;
    }
}

export function getItemsProducts(state) {
    return state.currencyRates.product;
}

export function getItemData(state) {
    return state.currencyRates.items;
}

export function getItemCart(state) {
    return state.currencyRates.cart;
}

export function getItemSize(state) {
    return state.currencyRates.selectSizes;
}

export function getItemType(state) {
    return state.currencyRates.selectType;
}

export function getItemQty(state) {
    return state.currencyRates.selectQty;
}

export function getItemDelIndex(state) {
    return state.currencyRates.del;
}

export function getPopout(state) {
    return state.currencyRates.popout;
}

export function getOrderID(state) {
    return state.currencyRates.orderId;
}
export function getDataOrderURL(state) {
    return state.currencyRates.dataSendOrder;
}
export function getSumDelivery(state) {
    return state.currencyRates.sumDelivery;
}
export function getTypeDelivery(state) {
    return state.currencyRates.typeDelivery;
}

export function getVKPAYData(state) {
    return state.currencyRates.VKPAYDataSend;
}

export function getUserName(state) {
    return state.currencyRates.userName;
}
export function getUserEmail(state) {
    return state.currencyRates.userEmail;
}
export function getUserPhone(state) {
    return state.currencyRates.userPhone;
}
export function getUserAddress(state) {
    return state.currencyRates.userAddress;
}
export function getDelAddress(state) {
    return state.currencyRates.delAddress;
}

export function getDaData(state) {
    return state.currencyRates.infoDaData;
}

export function getDeliveryInfo(state) {
    return state.currencyRates.infoDelivery;
}
export function getDeliveryRec(state) {
    return state.currencyRates.infoDelRecomended;
}

//Доставка
export function getPickup(state) {
    return state.currencyRates.pickup;
}
export function getMPickup(state) {
    return state.currencyRates.Mpickup;
}
export function getDayDel(state) {
    return state.currencyRates.dayDel;
}
//Fake
export function getTextTypeDelivery(state) {
    return state.currencyRates.textTypeDelivery;
}
export function getTextTypeDeliveryPunkt(state) {
    return state.currencyRates.textTypeDeliveryPunkt;
}
//verified
export function getVerDeliveryPunkt(state) {
    return state.currencyRates.verifiedDeliveryPunkt;
}
//printbar.ru/api/internal/send/weborder/?key=3f7cdb1eb04b643852633bde6f02611ef0f4d4c8&login=lootboxrussia@gmail.com&type=online&user_name=test%20test&user_email=test@mail.ru&user_phone=81111111111&delivery=2&payment=1&address=%D0%B3%20%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0,%20%D0%BF%D1%80-%D0%BA%D1%82%20%D0%97%D0%B0%D1%89%D0%B8%D1%82%D0%BD%D0%B8%D0%BA%D0%BE%D0%B2%20%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D1%8B&address_detail=&del_adres=%D0%B3.%20%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0,%20%D1%81%D1%82.%20%D0%BC.%20%D0%9A%D0%BE%D0%BC%D1%81%D0%BE%D0%BC%D0%BE%D0%BB%D1%8C%D1%81%D0%BA%D0%B0%D1%8F,%20%D1%83%D0%BB.%20%D0%9A%D1%80%D0%B0%D1%81%D0%BD%D0%BE%D0%BF%D1%80%D1%83%D0%B4%D0%BD%D0%B0%D1%8F,%20%D0%B4.%207-9&price_itog=2689&discount[sposob]=12&sended=0&products[0][id_product]=1938298&products[0][id_type]=3&products[0][code]=HIP-526804-swi-2&products[0][name]=%D0%A5%D0%B8%D0%BF%D1%81%D1%82%D0%B5%D1%80&products[0][type]=product&products[0][sex]=2&products[0][size]=M&products[0][price]=2390&products[0][count]=1&products[0][m_photo]=https://printbar.ru/upload/images/f6/f61d894.jpg