import React, {Component} from 'react';
import { platform, IOS } from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import HeaderButton from '@vkontakte/vkui/dist/components/HeaderButton/HeaderButton';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import List from '@vkontakte/vkui/dist/components/List/List';
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';
import Input from '@vkontakte/vkui/dist/components/Input/Input';
import Checkbox from '@vkontakte/vkui/dist/components/Checkbox/Checkbox';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import Link from '@vkontakte/vkui/dist/components/Link/Link';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import Alert from '@vkontakte/vkui/dist/components/Alert/Alert';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import {connect} from 'react-redux';
import {goBack, push} from 'connected-react-router';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import Icon36Cancel from '@vkontakte/icons/dist/36/cancel';
import '../style/cart.css';
import * as currencyItemsProducts from "../store/shop/reducer";
import * as currencyProducts from "../store/shop/actions";
import * as vkSelectors from "../store/vk/reducer";
import * as vkActions from "../store/vk/actions";

import Delivery from './modules/Delivery';



import { saveState, getState } from '../services/storage';

import axios from "axios";

let Buffer = require('buffer/').Buffer;
let md5 = require('md5');
let sha1 = require('sha1');

class Cart extends Component {

    componentWillMount() {
        this.props.dispatch(currencyProducts.fetchItemListProducts());
        this.isName(this.props.userName);
        this.isEmail(this.props.userEmail);
        this.isPhone(this.props.userPhone);
        this.isAddress()
    }
    componentDidMount() {
        this.props.dispatch(currencyProducts.fetchItemListProducts());
        this.isName(this.props.userName);
        this.isEmail(this.props.userEmail);
        this.isPhone(this.props.userPhone);
        this.isAddress()
    }
    constructor(props) {
        super(props);
        this.state = {
            sum: 0,
            addressGeoLocation: '',
            userAgreement: false,
            // !verified
            cartName:this.props.cart.map((item,index)=>{return item.name + index}),
            verifiedName: false,
            verifiedPhone: false,
            verifiedEmail: false,
            verifiedDeliveryPunkt: false,
            inputCity: getState('city') || '' ,
            name: getState('userName') || '',
            phoneNumber:  getState('userPhone') || '',
            email:  getState('userEmail') || '',
            userAddress: '',
            //Mpickup: getState('Mpickup') || null,
            //            //pickup: getState('pickup')  || null,
        };

        this.onChange = this.onChange.bind(this);
        this.onFetchName = this.onFetchName.bind(this);
        this.onFetchEmail = this.onFetchEmail.bind(this);
        this.onFetchPhone = this.onFetchPhone.bind(this);
    }

    onChange(e) {
        //console.log(e);
        //console.log(e.currentTarget);
        //console.log(e.currentTarget.name);
        //console.log(e.currentTarget.value);
        const { name, value } = e.currentTarget;
        this.setState({ [name]: value });
        if (name === 'name') {
            saveState('userName', value);
            this.isName(value);
            this.onChangeName(value);
        }
        if (name === 'email') {
            saveState('userEmail', value);
            this.isEmail(value);
            this.onChangeEmail(value);
        }
        if (name === 'phoneNumber') {
            saveState('userPhone', value);
            this.isPhone(value);
            this.onChangePhone(value);
        }
    }

    get itemCart () {
        return this.props.cart
    }

    onFetchName() {
        if (this.props.userName === '' && this.props.name === null) {
            this.props.dispatch(vkActions.fetchName()).then(this.setState({name: getState('userName')}));
            this.setState({name: getState('userName') || this.props.name});
        }
    }

    onFetchEmail() {
        if (this.props.userEmail === '' && this.props.email === null) {
            this.props.dispatch(vkActions.fetchEmail()).then(this.setState({email: getState('userEmail')}));
            this.setState({email: getState('userEmail') || this.props.email});
        }
    }

    onFetchPhone() {
        if (this.props.userPhone === '' && this.props.phoneNumber === null) {
            this.props.dispatch(vkActions.fetchPhone()).then(this.setState({phoneNumber: getState('userPhone')}));
            this.setState({phoneNumber: getState('userPhone') || this.props.phoneNumber});
        }
    }
    //onFetchGEO() {
    //    if (!this.props.userAddress) {onFetchName
    //        this.props.dispatch(vkActions.fetchAddressGeo());
    //    }
    //}
    //onFetchAddress() {
    //    let geoCode = this.props.addressGeo;
    //    if (this.props.addressGeo != null && this.props.addressGeo != '') {
    //        geocoder.reverse({ lat: geoCode[0], lon: geoCode[1] ,countryCode: 'ru'}).then((entries) => {
    //            this.setState({addressGeoLocation: [entries[0].state, entries[0].streetName, entries[0].streetNumber]});
    //        });
    //        return false
    //    }
    //    this.onFetchGEO();
    //}


    onChangeName(name) {
        this.props.dispatch(currencyProducts.setUserName(name));
        this.setState({name: name})
    }
    onChangeEmail(email) {
        this.props.dispatch(currencyProducts.setUserEmail(email));
        this.setState({email: email})
    }
    onChangePhone(phone) {
        this.props.dispatch(currencyProducts.setUserPhone(phone));
        this.setState({phoneNumber: phone})
    }
    onChangeAddress(address) {
        this.props.dispatch(currencyProducts.setUserAddress(address.target.value));
    }

    itemEnebled() {
        if (
            this.state.verifiedName !== false &&
            this.state.verifiedPhone !== false &&
            this.state.verifiedEmail !== false &&
            //this.props.cart.length > 0 &&
            //this.props.userPhone !== '' &&
            //this.props.userEmail !== '' &&
            //this.props.userAddress !== '' &&
            this.props.verifiedDeliveryPunkt !== false &&
            //this.props.textTypeDelivery !== '' &&
            //this.props.textTypeDeliveryPunkt !== '' &&
            //this.props.userPhone !== '' &&
            this.state.userAgreement !== false
        ) {
                return 1;
        }
        return 0
    }
    userAgreement() {
        if (this.state.userAgreement === false) {
            this.setState({userAgreement: true});
            return false
        }
        this.setState({userAgreement: false})
    }
    // Кастомная валиация
    isName(value) {
        if (value.length < 6) {
            return this.setState({verifiedName:false});
        }
        return this.setState({verifiedName:true});
    }
    isEmail(value) {
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(String(value).toLowerCase())) {
            return this.setState({verifiedEmail:true});
        } else {
            return this.setState({verifiedEmail:false});
        }
    }
    isPhone(value) {
        if (typeof value.match(/^[0-9]+$/) == null || value.length < 11) {
            return this.setState({verifiedPhone:false});
        }
        return this.setState({verifiedPhone:true});
    }
    isAddress() {
        if (this.props.pickup !== null && this.props.textTypeDeliveryPunkt !== '') {
            return      this.props.dispatch(currencyProducts.setVerDeliveryPunkt(true));
        } else if ((this.props.Mpickup !== 'printbar_pickup' ||  this.props.Mpickup !== 'pochta_pickup' ||  this.props.Mpickup !== 'dpd_pickup' ||  this.props.Mpickup !== 'sdek_pickup') && this.props.textTypeDelivery !== '' && this.props.userAddress !== '') {
            return      this.props.dispatch(currencyProducts.setVerDeliveryPunkt(true));
        }
        return this.props.dispatch(currencyProducts.setVerDeliveryPunkt(false));
    }
    // END Кастомная валиация
    render() {
        const osname = platform();
        let self = this;
        let {selectType,} = this.props;
        let sumFix = (this.props.sumDelivery == undefined ? 0 : this.
            props.sumDelivery);
        let sum = this.state.sum;
        for (let index in this.itemCart) {
            sum += parseInt((parseInt(this.props.cart[index].price)+parseInt(selectType[index] == 'normal' ? 0: (selectType[index]== 'premium' ? 199:  299))) * this.props.selectQty[index]);
        }
        let sumAll = sum + (sumFix == 0 ? 0 : this.props.sumDelivery);

        const { email, name, phoneNumber } = this.state;
        return (
            <Panel id={this.props.id}>
                <PanelHeader
                    left={<HeaderButton onClick={this.navigationBack.bind(this)}>{osname === IOS ?
                    <Icon28ChevronBack/> : <Icon24Back/>}</HeaderButton>}
                >
                    Корзина
                </PanelHeader>
                    {this.itemCart.length > 0 ?
                        <React.Fragment>
                            <Group title="Корзина">
                                <List>
                                    {this.state.cartName.map((item,index) => {
                                        return (
                                            <React.Fragment>
                                                {self.InfoProductCart(item,index)}
                                            </React.Fragment>
                                        )
                                    })}
                                </List>
                                <List className='cart-sum'>
                                    {this.props.cart.length} товаров на сумму ({(this.props.sumDelivery == undefined ? 'без учета' : 'с учетом')} доставки) <nobr
                                    className='cart-sum-value'>{sumAll}.00 &#8381;</nobr>
                                </List>
                            </Group>
                            <Group title='Контактные данные'>
                                <FormLayout>
                                    {/*<div>1{this.props.name}</div>*/}
                                    {/*<div>11{name}</div>*/}
                                    {/*<div>2{this.props.userName}</div>*/}
                                    {/*<div>3{this.state.name}</div>*/}
                                    {/*<div>1{this.props.phoneNumber}</div>*/}
                                    {/*<div>2{this.props.userPhone}</div>*/}
                                    {/*<div>3{this.state.phoneNumber}</div>*/}
                                    {/*<div>1{this.props.email}</div>*/}
                                    {/*<div>3{this.props.userEmail}</div>*/}
                                    {/*<div>2{this.state.email}</div>*/}
                                    <Input
                                        top="Имя и фамилия"
                                        type="text"
                                        name="name"
                                        value={this.state.name === null || this.props.name === null ? this.props.name: this.state.name !== null ? this.state.name : this.state.name}
                                        status={this.state.verifiedName !== false? 'valid' : 'error'}
                                        bottom={this.state.verifiedName !== false ? 'Имя и фамилия введены верно!' : 'Пожалуйста, введите полное имя и фамилия'}
                                        onFocus={this.onFetchName}
                                        defaultValue={this.state.userName === null ? this.props.userName: this.props.userName === ''  ? (this.props.userName + this.props.userName) : this.props.userName}
                                        //value={this.state.name === null || this.props.name === null ? this.props.name: this.state.name !== null ? this.state.name : this.state.name}
                                        onChange={this.onChange}
                                        onBlur={(name) => {
                                            this.onChangeName(name.target.value);
                                            this.isName(name.target.value);
                                        }}
                                    />
                                    <Input
                                        top="Мобильный телефон"
                                        type="tel"
                                        name="phoneNumber"
                                        status={(this.state.verifiedPhone !== false) ? 'valid' : 'error'}
                                        bottom={(this.state.verifiedPhone !== false) ? 'Мобильный телефон введен верно!' : 'Пожалуйста, введите номер мобильного телефона'}
                                        defaultValue={getState('userPhone')}
                                        value={getState('userPhone') || this.props.phoneNumber || this.state.phoneNumber}
                                        //value={getState('userPhone') || this.props.phoneNumber || this.state.phoneNumber}
                                        onFocus={this.onFetchPhone}
                                        onChange={this.onChange}
                                        onBlur={(phone) => {
                                            this.onChangePhone(phone.target.value);
                                            this.isPhone(phone.target.value);
                                        }}
                                    />
                                    <Input
                                        top="Email"
                                        type="email"
                                        name='email'
                                        status={(this.state.verifiedEmail !== false ) ? 'valid' : 'error'}
                                        bottom={(this.state.verifiedEmail !== false ) ? 'Электронная почта введена верно!' : 'Пожалуйста, введите электронную почту'}
                                        defaultValue={this.state.userEmail === null ? this.props.userEmail: this.props.userEmail === ''  ? (this.props.userEmail + this.props.userEmail) : this.props.userEmail}
                                        value={getState('userEmail') || this.props.email || this.state.email}
                                        //value={getState('userEmail') || this.props.email || this.state.email}
                                        onFocus={this.onFetchEmail}
                                        onChange={this.onChange}
                                        onBlur={(email) => {
                                            this.onChangeEmail(email.target.value);
                                            this.isEmail(email.target.value)
                                        }}
                                    />

                                </FormLayout>
                            </Group>
                            <Group title='Доставка'>
                                <Delivery/>
                            </Group>
                            <Group>
                                <FormLayout>
                                    <Checkbox onChange={()=>this.userAgreement({userAgreement: true})}>Заполняя форму вы соглашаетесь с обработкой персональных данных согласно <Link href="https://printbar.ru/documents/polzovatelskoe-soglashenie/" target="_blank">пользовательского соглашения</Link></Checkbox>
                                </FormLayout>
                            </Group>
                            <Group>
                                <Div>
                                    <Button disabled={this.itemEnebled() === 1 ? false : true} appearance='vk-rich' size="xl"
                                               onClick={() => {
                                                   this.printbarAPI();
                                               }}>Оплатить через <span className="iconVKpay"></span></Button>
                                </Div>
                            </Group>
                        </React.Fragment>
                        :
                        <Div align="center" style={{
                            height: '60vh',
                            display: 'flex',
                            flexDirection: 'column',
                            flexWrap: 'nowrap',
                            justifyContent: 'center',
                            alignContent: 'stretch',
                            alignItems: 'center'
                        }}>
                            <span style={{
                                borderRadius:' 50%',
                                backgroundColor: '#dfe2e6',
                                color: '#99a2ad',
                            }}><Icon36Cancel/></span>
                            <Div style={{
                                flex: '0 0 auto',
                                color: '#99a2ad',
                                textAlign: 'center'
                            }}>
                                Корзина пуста
                            </Div>
                            <Div>
                                <Button size="xl" onClick={() => this.props.dispatch(push(''))} >Подберите себе принт</Button>

                            </Div>
                        </Div>
                    }
            </Panel>
        )
    }
    setPopoutNull(e) {
        this.props.dispatch(currencyProducts.setPopout(e === null ? null: e));
        this.sendVKPAY();
    }

    openNotifyDialog(data) {
        let self = this;
        let orderID = data.id_order;
        const {selectType} = this.props;
        let sumFix = (this.props.sumDelivery == undefined ? 0 : this.props.sumDelivery);
        let sum = this.state.sum;
        for (let index in this.itemCart) {
            sum += parseInt((parseInt(this.props.cart[index].price)+parseInt(selectType[index] == 'normal' ? 0: (selectType[index]== 'premium' ? 299:  399))) * this.props.selectQty[index]);
        }
        //let sumAll = 1;
        let sumAll = sum + (sumFix == 0 ? 0 : this.props.sumDelivery);
        this.props.dispatch(currencyProducts.setPopout(
            <Alert
                actions={[{
                    title: 'Подтверждаю',
                    autoclose: true,
                    style: 'destructive'
                }]}
                onClose={() => {
                    self.setPopoutNull()
                }}>

                <p>Итого к оплате {(this.props.sumDelivery == undefined ? '' : 'с учетом доставки')}</p>
                <p>ID заказа {orderID}</p>
                <br/>
                <h2>{sumAll}.00 &#8381;</h2>
            </Alert>
        ));
    }
    // API заказ товара
    InfoProductCart(item,index) {
        let self                       = this;
        let {cart, selectSizes, selectType, selectQty} = this.props;
        return (
            <Cell key={item}
                  removable
                  multiline
                  onRemove={() => {
                      this.setState({
                          cartName: [...this.state.cartName.slice(0, index), ...this.state.cartName.slice(index + 1)]
                      });
                      self.removeItemCaty(index);
                  }}
                  photo={cart[index].image}
                  removePlaceholder={'Удалить'}
                  description={
                      <div>{cart[index].type} ({cart[index].sex}) - {selectType[index] == 'normal' ? 'Обычная ткань' : (selectType[index] == 'premium' ? 'Премиум ткань' : "Люкс ткань")}</div>}
                  asideContent={
                      <div>
                          {selectQty[index] > 1 ? selectQty[index] + ' x' : null} {parseInt(cart[index].price) + parseInt(selectType[index] == 'normal' ? 0 : (selectType[index] == 'premium' ? 299 : 399))} &#8381;
                      </div>
                  }
                  before={<Avatar type="image" alt={cart[index].name} src={cart[index].image.front.thumb} size={80}/>}
                     size="l"

            >
                {cart[index].name}  ({selectSizes[index]})
            </Cell>
        )
    }
    getItemListProducts(dataSend) {
        let self = this;
        //let response = {data:{id_order:111111}};
        self.props.dispatch(currencyProducts.setDataSendOrder(dataSend));
        axios.post(dataSend).then(response => {
            self.props.dispatch(currencyProducts.setOrderID(response.data));
            self.props.dispatch(currencyProducts.setDataSendOrder(dataSend));
            self.addDataCart(response.data);
            this.openNotifyDialog(response.data)
        })
            .catch((error) => {
                console.log(error);
                return false
            });
        //self.props.dispatch(currencyProducts.setDataSendOrder(dataSend));
        //axios.post().then(test => {
        //    self.props.dispatch(currencyProducts.setOrderID(response.data));
        //    self.props.dispatch(currencyProducts.setDataSendOrder(dataSend));
        //    self.addDataCart(response.data);
        //})
        //    .catch((error) => {
        //        self.props.dispatch(currencyProducts.setOrderID(response.data));
        //        self.props.dispatch(currencyProducts.setDataSendOrder(dataSend));
        //        self.addDataCart(response.data);
        //    });
    }
    printbarAPI  =  () => {
        const {cart, selectSizes, selectType, selectQty} = this.props;
        let sumFix = (this.props.sumDelivery == undefined ? 0 : this.props.sumDelivery);
        let sum = this.state.sum;
        for (let index in this.itemCart) {
            sum += parseInt((parseInt(this.props.cart[index].price)+parseInt(selectType[index] == 'normal' ? 0: (selectType[index]== 'premium' ? 299:  399))) * this.props.selectQty[index]);
        }
        let sumAll = sum + (sumFix == 0 ? 0 : this.props.sumDelivery);

        //let cartMAP = this.props.cart.map(this.props.cart.name);

        const url   = 'https://printbar.ru/api/internal/send/weborder/'; //боевое API
        //const url   = 'http://prog2.advsupport.ru/api/v2'; // тестовый API (уничтожен)
        const key   = '3f7cdb1eb04b643852633bde6f02611ef0f4d4c8';
        const login = 'lootboxrussia@gmail.com';
        const type  = 'online';

        const mappingFunction = (p, index)  =>
            "&products["+[index]+"][id_product]="+[p.id_product]+"" +
            "&products["+[index]+"][id_type]="+[p.id_type]+""+
            "&products["+[index]+"][code]="+[p.art]+"" +
            "&products["+[index]+"][name]="+[p.collection]+"" +
            "&products["+[index]+"][type]=product" +
            "&products["+[index]+"][sex]=" +[p.sex=== 'Мужчинам' ? '2': '1']+"" +
            "&products["+[index]+"][size]="+[selectSizes[index]]+
            "&products["+[index]+"][price]="+[(parseInt(p.price)+parseInt(selectType[index] == 'normal' ? 0: (selectType[index] == 'premium' ? 299:  399)))]+"" +
            "&products["+[index]+"][count]="+[selectQty[index]]+
            "&products["+[index]+"][m_photo]="+[p.image.front.img]+""+
            "&products["+[index]+"][dops]=" + (selectType[index] == 'normal' ? '': (JSON.stringify({"1":{"name":(selectType[index] == 'premium' ? 'premium':  'lux'),"type":"1","cost":(selectType[index] == 'premium' ? '299':  '399')}}))) +"";

        let cartMAP = cart.map(mappingFunction);

        let dataSendOrder = `${url}?key=${key}&login=${login}&type=${type}&user_name=${this.props.userName}&user_email=${this.props.userEmail}&user_phone=${this.props.userPhone}&delivery=${this.props.typeDelivery}&payment=1&address=${this.state.inputCity}&address_detail=${this.props.userAddress}&del_adres=${this.props.delAddress}&price_itog=${sumAll}&discount[sposob]=12&sended=0${cartMAP}`;
        this.getItemListProducts(dataSendOrder)
    };

    addDataCart(data) {
        let self = this;
        let orderID = data.id_order;
        const {selectType} = this.props;
        let sumFix = (this.props.sumDelivery == undefined ? 0 : this.props.sumDelivery);
        let sum = this.state.sum;
        for (let index in this.itemCart) {
            sum += parseInt((parseInt(this.props.cart[index].price)+parseInt(selectType[index] == 'normal' ? 0: (selectType[index] == 'premium' ? 299:  399))) * this.props.selectQty[index]);
        }
        //let sumAll = 1;
        let sumAll = sum + (sumFix == 0 ? 0 : this.props.sumDelivery);

        //Ключи для приложения
        let merchantID = '249465';
        let merchantKey = '0a036a80cd15f8bd624f4c92a25d8b90a2454846';
        let SecretKey = '6BsY9FvYhaCsSapIbFOI';

        //Формирующаяся информация для приложения
        let dataNow = (new Date).getTime();
        let merchantDataForCrypt = {
            "order_id": ''+orderID+'',
            "ts": ''+dataNow+'',
            "amount": sumAll,
            "currency": "RUB",
            "cashback": {
                "pay_time": (dataNow+1800),
                "amount_percent": 5
            }
        };
        let merchantData = Buffer.from(JSON.stringify(merchantDataForCrypt)).toString('base64');

        let merchantSign = sha1(merchantData+merchantKey);

        let forSign = 'amount='+sumAll+'data={"order_id":"'+orderID+'","ts":"'+dataNow+'","currency":"RUB","cashback":{"pay_time":'+(dataNow+1800)+',"amount_percent":5},"merchant_data":"'+merchantData+'","merchant_sign":"'+merchantSign+'"}description=\u0417\u0430\u043a\u0430\u0437 \u2116 '+orderID+'merchant_id='+merchantID+SecretKey+'';
        let Sign = md5(forSign);

        let dataSend = {"amount":sumAll,"data":"{\"order_id\":\""+orderID+"\",\"ts\":\""+dataNow+"\",\"currency\":\"RUB\",\"cashback\":{\"pay_time\":"+(dataNow+1800)+",\"amount_percent\":5},\"merchant_data\":\""+merchantData+"\",\"merchant_sign\":\""+merchantSign+"\"}","description":"\u0417\u0430\u043a\u0430\u0437 \u2116 "+orderID+"","action":"pay-to-service","merchant_id":""+merchantID+"","sign":""+Sign+""};
        self.props.dispatch(currencyProducts.setDataSendVKPAY(dataSend));
        saveState('cart', null)
    }
    sendVKPAY () {
            this.props.dispatch(vkActions.sendVKPAY(this.props.VKPAYDataSend));
        }

    // END API заказ товара
    removeItemCaty(index) {
        this.props.dispatch(currencyProducts.delItemCart(index));
    }

    navigationBack() {
        this.props.dispatch(goBack());
    }
}
function mapStateToProps(state) {
    return {
        // Узнаем информацию о пользователи из VK API
        phoneNumber: vkSelectors.getPhoneNumber(state),
        email: vkSelectors.getEmail(state),
        name: vkSelectors.getName(state),
        //addressGeo: vkSelectors.getGEO(state),

        //Получаем список товаров(Полный)
        product: currencyItemsProducts.getItemsProducts(state),

        //Класические запросы
        userName: currencyItemsProducts.getUserName(state),
        userEmail: currencyItemsProducts.getUserEmail(state),
        userPhone: currencyItemsProducts.getUserPhone(state),
        userAddress: currencyItemsProducts.getUserAddress(state),
        delAddress: currencyItemsProducts.getDelAddress(state),

        cart: currencyItemsProducts.getItemCart(state),
        selectSizes: currencyItemsProducts.getItemSize(state),
        selectType: currencyItemsProducts.getItemType(state),
        selectQty: currencyItemsProducts.getItemQty(state),
        orderId: currencyItemsProducts.getOrderID(state),
        dataSendOrder: currencyItemsProducts.getDataOrderURL(state),
        sumDelivery: currencyItemsProducts.getSumDelivery(state),
        typeDelivery: currencyItemsProducts.getTypeDelivery(state),

        textTypeDelivery: currencyItemsProducts.getTextTypeDelivery(state),
        textTypeDeliveryPunkt: currencyItemsProducts.getTextTypeDeliveryPunkt(state),

        verifiedDeliveryPunkt: currencyItemsProducts.getVerDeliveryPunkt(state),

        VKPAYDataSend: currencyItemsProducts.getVKPAYData(state),
    };
}

export default connect(mapStateToProps)(Cart);

