import Immutable from 'seamless-immutable';
import * as types from './actionTypes';
import {saveState} from "../../services/storage";

const initialState = Immutable({
    vk: undefined,
    phoneNumber: null,
    email: null,
    name: null,
});

export default function reduce(state = initialState, action) {
    switch (action.type) {
        case types.VK_GET_ACCESS_TOKEN_FETCHED:
            return state.merge({
                accessToken: action.accessToken
            });
        case types.VK_NOTIFICATION_STATUS_FETCHED:
            return state.merge({
                notificationStatus: action.notificationStatus
            });
        case types.VK_PHONE_FETCHED:
            return  saveState('userPhone', action.phoneNumber), state.merge({
                phoneNumber: action.phoneNumber
            });
        case types.VK_EMAIL_FETCHED:
            return saveState('userEmail', action.email), state.merge({
                email: action.email
            });
        case types.VK_NAME_FETCHED:
            return saveState('userName', action.name), state.merge({
                name: action.name
            });
        case types.VK_GEO_FETCHED:
            return state.merge({
                addressGeo: action.addressGeo
            });
        case types.VK_INSETS_FETCHED:
            return state.merge({
                insets: action.insets
            });
        case types.VK_GET_ACCESS_TOKEN_FAILED:
            return state.merge({
                logs: action.error
            });
        case types.VK_NOTIFICATION_STATUS_FAILED:
            return state.merge({
                logs: action.error
            });
        default:
            return state;
    }
}

export function getAccessToken(state) {
    return state.vk.accessToken;
}

export function getNotificationStatus(state) {
    return state.vk.notificationStatus;
}

export function getPhoneNumber(state) {
    return state.vk.phoneNumber;
}

export function getEmail(state) {
    return state.vk.email;
}

export function getName(state) {
    return state.vk.name;
}

export function getGEO(state) {
    return state.vk.addressGeo;
}

export function getGroupInvite(state) {
    return state.vk.groupInvite;
}

export function getInsets(state) {
    return state.vk.insets;
}

export function getLogs(state) {
    return JSON.stringify(state.vk.logs);
}
