import React, {Component} from 'react';
import { platform, IOS } from '@vkontakte/vkui';

import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import HeaderButton from '@vkontakte/vkui/dist/components/HeaderButton/HeaderButton';
import ActionSheet from '@vkontakte/vkui/dist/components/ActionSheet/ActionSheet';
import ActionSheetItem from '@vkontakte/vkui/dist/components/ActionSheetItem/ActionSheetItem';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Gallery from '@vkontakte/vkui/dist/components/Gallery/Gallery';
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import List from '@vkontakte/vkui/dist/components/List/List';
import InfoRow from '@vkontakte/vkui/dist/components/InfoRow/InfoRow';
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';
import Select from '@vkontakte/vkui/dist/components/Select/Select';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import {connect} from 'react-redux';
import {goBack, push} from 'connected-react-router';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import '../style/iteminfo.css';
import * as currencyItemsProducts from "../store/shop/reducer";
import * as currencyProducts from "../store/shop/actions";

import Icon24Help from '@vkontakte/icons/dist/24/help';

import Image from 'react-image-resizer';
import LazyLoad from 'react-lazyload';

import { saveState, getState } from '../services/storage';

class ConnectedItemInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itemData : this.props.items,
            popout: null,
            available_sizes: ['XS','S','M','L','XL','XXL','XXXL','4XL','5XL'],
            selectSizesProduct: getState('selectSizesProduct') || 'M',
            selectTypeSum: 0,
            selectTypeProduct: getState('selectTypeProduct') || 'normal',
            selectQtyProduct: getState('selectQtyProduct') || '1',
        };
    }
    setPopoutNull(e) {
        this.props.dispatch(currencyProducts.setPopout(e === null ? null: e))
    }
    handleChangeFabricType(event) {
        saveState('selectTypeProduct', event.target.value);
        this.setState({selectTypeProduct: event.target.value});

    }
    handleChangeSizes(event) {
        saveState('selectSizesProduct', event.target.value);
        this.setState({selectSizesProduct: event.target.value});

    }
    handleChangeQty(event) {
        saveState('selectQtyProduct', event.target.value);
        this.setState({selectQtyProduct: event.target.value});
    }
    openNotifyDialog() {
        this.props.dispatch(currencyProducts.setPopout(
            <ActionSheet
                onClose={() => this.setPopoutNull(null)}>
                <ActionSheetItem autoclose>Продолжить покупку</ActionSheetItem>
                <ActionSheetItem autoclose onClick={() => {
                    this.props.dispatch(push('/basket'));}}>
                    Перейти в корзину</ActionSheetItem>
            </ActionSheet>
        ));
    }



    render() {
        const osname = platform();
        return (
                <Panel id={this.props.id}>
                    <PanelHeader
                        left={<HeaderButton onClick={this.navigationBack.bind(this)}>{osname === IOS ?
                            <Icon28ChevronBack/> : <Icon24Back/>}</HeaderButton>}
                    >
                        Товар
                    </PanelHeader>
                    {this.InfoProduct()}
                </Panel>
        );
    }

    InfoProduct() {
        const {selectSizesProduct, selectTypeProduct, selectQtyProduct} = this.state;
        const itemData = this.state.itemData;
        const itemDataSize = this.state.available_sizes;
        let sumFabric = parseInt(selectTypeProduct === 'normal' ? 0: (selectTypeProduct === 'premium' ? 299:  399));
        if (!this.props.items) {
            return (<Div align="center" style={{
                height: '80vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div style={{
                    flex: '0 0 auto',
                    color: 'gray',
                    textAlign: 'center'
                }}>
                    <Button size="xl" onClick={() => this.props.dispatch(push(''))}>Выберете товар</Button>
                </div>
            </Div>);
        }
        //console.log(getState('selectTypeProduct'));
        return (
            <React.Fragment>
                <Group>
                    <Gallery slideWidth="100%" align="center" style={{ height: 300 }} bullets="dark" className='text-center'>
                        <div align="center">
                            <LazyLoad height={300}>
                            <Image alt={itemData.name} height={300} width={280} style={{position: 'static'}} src={itemData.image.front.img}/>
                            </LazyLoad>
                        </div>
                        {itemData.image === undefined ? null : (itemData.image.back === undefined ? null :
                        <div align="center">
                            <LazyLoad height={300}>
                            <Image alt={itemData.name} height={300} width={280} style={{position: 'static'}} src={itemData.image.back.img}/>
                            </LazyLoad>
                        </div>
                            )}
                    </Gallery>
                    <div className='iteminfo-name'>{itemData.name}</div>
                    <div className='iteminfo-sex'>{itemData.sex === 'Женщинам' ? 'Женская': 'Мужская'} / {itemData.type}</div>

                    <div className='iteminfo-price'>{itemData.price_old === '0' ? null : <span className="iteminfo-price-old">{itemData.price_old} &#8381;</span>} {parseInt(itemData.price) + sumFabric} &#8381;</div>
                    <div className='iteminfo-description'>{itemData.description}</div>
                </Group>
                <Group title="Информация о товаре">
                    <List>
                        <Cell>
                            <InfoRow title="Качество">
                                <div className="productInformation">
                                    Экологичная ULTRA HD печать
                                </div>
                            </InfoRow>
                        </Cell>
                        <Cell>
                            <InfoRow title="Принт">
                                <div className="productInformation">
                                    Выдерживает неограниченное количество стирок
                                </div>
                            </InfoRow>
                        </Cell>
                        <Cell>
                            <InfoRow title="Производство">
                                <div className="productInformation">
                                    72 часа
                                </div>
                            </InfoRow>
                        </Cell>
                    </List>
                </Group>
                <Group>
                    <div>
                        <FormLayout>
                            <Select
                                top="Количество"
                                defaultValue={selectQtyProduct}
                                onChange={(e) => this.handleChangeQty(e)}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </Select>
                            <div top="Размер" style={{display: 'flex'}}>
                                <Select style={{ width: '100%'}}
                                    defaultValue={selectSizesProduct}
                                    onChange={(e) => this.handleChangeSizes(e)}>
                                    {
                                        itemDataSize.map(function (itemSize, index) {
                                            return (
                                                <option key={index} value={itemSize}>{itemSize}</option>
                                            );
                                        })
                                    }
                                </Select>
                                <Button style={{ marginLeft: 0 }} level="secondary" onClick={() => {
                                    this.props.dispatch(push('/size'));}}><Icon24Help/></Button>
                            </div>
                            <div top="Тип ткани" style={{display: 'flex'}}>
                                <Select style={{ width: '100%'}}
                                    defaultValue={selectTypeProduct}
                                    onChange={(e) => this.handleChangeFabricType(e)}>
                                    <option value="normal" data-cost="0" data-name="">Обычная ткань</option>
                                    <option value="premium" data-cost="299" data-name="premium">Премиум ткань (+299 &#8381;)</option>
                                    <option value="lux" data-cost="399" data-name="lux">Люкс ткань (+399 &#8381;)</option>
                                </Select>
                                <Button style={{ marginLeft: 0 }}  level="secondary" onClick={() => {
                                    this.props.dispatch(push('/fabric'));}}><Icon24Help/></Button>
                            </div>
                        </FormLayout>
                    </div>
                </Group>
                <Group>
                    <Div>
                    <Button appearance="vk-rich" size="xl"
                               onClick={() => {
                                   this.props.dispatch(currencyProducts.addItemCart(itemData));
                                   this.props.dispatch(currencyProducts.addItemCartSize(selectSizesProduct));
                                   this.props.dispatch(currencyProducts.addItemCartType(selectTypeProduct));
                                   this.props.dispatch(currencyProducts.addItemCartQty(selectQtyProduct));
                                   this.openNotifyDialog(this);
                               }}
                    >Добавить в корзину</Button>
                    </Div>
                </Group>
            </React.Fragment>
    );
    }
    navigationBack() {
        this.props.dispatch(goBack());
    }
}

function mapStateToProps(state) {
    return {
        items:currencyItemsProducts.getItemData(state),

        //Получаем список товаров(Полный)
        product: currencyItemsProducts.getItemsProducts(state),
    };
}

export default connect(mapStateToProps)(ConnectedItemInfo);
