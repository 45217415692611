import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createHashHistory} from 'history';

import createRootReducer from "./store/reducers";
import thunk from "redux-thunk";

const history = createHashHistory({
    hashType: 'noslash'
});

const logger = store => next => action => {
    //console.log('dispatching', action);
    return next(action);
};
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    createRootReducer(history),
    composeEnhancer(
        applyMiddleware(
            thunk, routerMiddleware(history), logger
        ),
    ),
);


export {store, history};
