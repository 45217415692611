import React, {Component} from 'react';
import { platform, IOS } from '@vkontakte/vkui';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import HeaderButton from '@vkontakte/vkui/dist/components/HeaderButton/HeaderButton';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import List from '@vkontakte/vkui/dist/components/List/List';
import {connect} from 'react-redux';
import {goBack} from 'connected-react-router';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import * as currencyItemsProducts from "../store/shop/reducer";
import * as currencyProducts from "../store/shop/actions";
import {saveState} from "../services/storage";

class SelectM extends Component {

    isAddress() {
        if (this.props.pickup !== null && this.props.textTypeDeliveryPunkt !== '') {
            return      this.props.dispatch(currencyProducts.setVerDeliveryPunkt(true));
        } else if ((this.props.Mpickup !== 'printbar_pickup' ||  this.props.Mpickup !== 'pochta_pickup' ||  this.props.Mpickup !== 'dpd_pickup' ||  this.props.Mpickup !== 'sdek_pickup') && this.props.textTypeDelivery !== '' && this.props.userAddress !== '') {
            return      this.props.dispatch(currencyProducts.setVerDeliveryPunkt(true));
        }
        return this.props.dispatch(currencyProducts.setVerDeliveryPunkt(false));
    }
    onTypeDelivery(value) {
        //console.log(value);
        let {infoDelivery} = this.props;
        if (value != '') {
            let target         =
                    (value == 'sdek') ? 'sdek' :
                        (value == 'sdek_pickup') ? 'sdek' :
                            (value == 'dpd') ? 'dpd' :
                                (value == 'dpd_pickup') ? 'dpd' :
                                    (value == 'pochta') ? "pochta" :
                                        (value == 'pochta_pickup') ? "pochta" :
                                            (value == 'printbar') ? "printbar" :
                                                (value == 'printbar_pickup') ? "printbar" : null;
            let targetDep      =
                    (value == 'sdek_pickup') ? infoDelivery[target].sklad.price[0] :
                        (value == 'dpd_pickup') ? infoDelivery[target].sklad.price[0] :
                            (value == 'pochta_pickup') ? infoDelivery[target].sklad.price[0] :
                                (value == 'printbar_pickup') ? infoDelivery[target].sklad.price[0] : infoDelivery[target].door.price[0];
            let deliverType      =
                    (value == 'sdek_pickup') ? infoDelivery[target].sklad.deliver_type[0] :
                        (value == 'dpd_pickup') ? infoDelivery[target].sklad.deliver_type[0] :
                            (value == 'pochta_pickup') ? infoDelivery[target].sklad.deliver_type[0] :
                                (value == 'printbar_pickup') ? infoDelivery[target].sklad.deliver_type[0] : infoDelivery[target].door.deliver_type[0];
            let day      =
                    (value == 'sdek_pickup') ? infoDelivery[target].sklad.days :
                        (value == 'dpd_pickup') ? infoDelivery[target].sklad.days :
                            (value == 'pochta_pickup') ? infoDelivery[target].sklad.days :
                                (value == 'printbar_pickup') ? infoDelivery[target].days : infoDelivery[target].door.days;
            //let deyPlus = parseInt(day + 3);
            let targetPickup        =
                    (value == 'sdek_pickup') ? 'sdek_pickup' :
                        (value == 'dpd_pickup') ? 'dpd_pickup' :
                            (value == 'pochta_pickup') ? "pochta_pickup" :
                                (value == 'printbar_pickup') ? "printbar_pickup" : null;

            this.props.dispatch(currencyProducts.setDeliverySum(
                (value == '' ? 0 : targetDep))
            );
            this.props.dispatch(currencyProducts.setDeliveryType(
                (value == '' ? 1 : deliverType))
            );
            this.props.dispatch(currencyProducts.setMPickup(targetPickup));
            saveState('Mpickup', value);
            {day == undefined ? this.props.dispatch(currencyProducts.setDayDel("1-2")) : this.props.dispatch(currencyProducts.setDayDel(day))}
        }
        else {
            this.props.dispatch(currencyProducts.setMPickup(null));
            saveState('Mpickup', null);
        }
    }
    onTypeDeliveryMethod(value) {
        //console.log(value);
        if (value != '') {
            let targetMethod = (value == 'sdek_pickup') ? 'sklad' :
                (value == 'dpd_pickup') ? 'sklad' :
                    (value == 'pochta_pickup') ? 'sklad' :
                        (value == 'printbar_pickup') ? 'sklad' : null;

            this.props.dispatch(currencyProducts.setPickup(targetMethod));
            saveState('pickup', value);
        }
        else {
            this.props.dispatch(currencyProducts.setPickup(null));
            saveState('pickup', null);
        }
    }
    render() {
        const osname = platform();

        return (
                <Panel id={this.props.id}>
                    <PanelHeader
                        left={<HeaderButton onClick={this.navigationBack.bind(this)}>{osname === IOS ?
                            <Icon28ChevronBack/> : <Icon24Back/>}</HeaderButton>}
                    >
                        Выбор страны
                    </PanelHeader>
                    <Group>
                        {this.renderDeliverySelectionMethod()}
                    </Group>
                </Panel>
        );
    }
    renderDeliverySelectionMethod() {
        let {infoDelivery} = this.props;
        if (infoDelivery !== undefined) {
            this.props.dispatch(currencyProducts.setTextTypePunkt(''));
            return (
                <List>
                    {infoDelivery.sdek === undefined ? null :
                        <Cell className='selectnew' value="sdek"
                              onClick={() => {
                                  this.onTypeDelivery('sdek');
                                  this.onTypeDeliveryMethod('sdek');
                                  this.navigationBack();
                                  this.isAddress();
                                  this.props.dispatch(currencyProducts.setTextTypeDelivery('СДЕК (До двери) (цена - '+infoDelivery.sdek.door.price[0]+' руб.)'));
                              }}
                              data-col={infoDelivery.sdek.door.price[0]} data-del={infoDelivery.sdek.door.deliver_type[0]}>
                            СДЕК (До двери) (цена - {infoDelivery.sdek.door.price[0]} руб.) {infoDelivery.sdek.door.days}
                        </Cell>
                    }
                    {infoDelivery.sdek === undefined ? null : (infoDelivery.sdek.sklad === undefined ? null :
                            <Cell className='selectnew' value="sdek_pickup"
                                  onClick={() => {
                                      this.onTypeDelivery('sdek_pickup');
                                      this.onTypeDeliveryMethod('sdek_pickup');
                                      this.navigationBack();
                                      this.isAddress();
                                      this.props.dispatch(currencyProducts.setTextTypeDelivery('СДЕК (До пункта выдачи) (цена - '+infoDelivery.sdek.sklad.price[0]+' руб.)'));
                                  }}
                                  data-col={infoDelivery.sdek.sklad.price[0]} data-del={infoDelivery.sdek.sklad.deliver_type[0]}>
                                СДЕК (До пункта выдачи) (цена - {infoDelivery.sdek.sklad.price[0]} руб.) {infoDelivery.sdek.sklad.days}
                            </Cell>
                    )}
                    {infoDelivery.dpd === undefined ? null :
                        <Cell className='selectnew' value="dpd"
                              onClick={() => {
                                  this.onTypeDelivery('dpd');
                                  this.onTypeDeliveryMethod('dpd');
                                  this.navigationBack();
                                  this.isAddress();
                                  this.props.dispatch(currencyProducts.setTextTypeDelivery('СДЕК (До пункта выдачи) (цена - ' + infoDelivery.dpd.door.price[0]+' руб.)'));
                              }}
                              data-col={infoDelivery.dpd.door.price[0]} data-del={infoDelivery.dpd.door.deliver_type[0]}>
                            ДПД (До двери) (цена - {infoDelivery.dpd.door.price[0]} руб.) {infoDelivery.dpd.door.days}
                        </Cell>
                    }
                    {infoDelivery.dpd === undefined ? null : (infoDelivery.dpd.sklad === undefined ? null :
                            <Cell className='selectnew' value="dpd_pickup"
                                  onClick={() => {
                                      this.onTypeDelivery('dpd_pickup');
                                      this.onTypeDeliveryMethod('dpd_pickup');
                                      this.navigationBack();
                                      this.props.dispatch(currencyProducts.setTextTypeDelivery('ДПД (До пункта выдачи) (цена - ' + infoDelivery.dpd.sklad.price[0]+' руб.)'));
                                  }}
                                  data-col={infoDelivery.dpd.sklad.price[0]} data-del={infoDelivery.dpd.sklad.deliver_type[0]}>
                                ДПД (До пункта выдачи) (цена - {infoDelivery.dpd.sklad.price[0]} руб.) {infoDelivery.dpd.door.days}
                            </Cell>
                    )}
                    {infoDelivery.pochta === undefined ? null :
                        <Cell className='selectnew' value="pochta"
                              onClick={() => {
                                  this.onTypeDelivery('pochta');
                                  this.onTypeDeliveryMethod('pochta');
                                  this.navigationBack();
                                  this.isAddress();
                                  this.props.dispatch(currencyProducts.setTextTypeDelivery('Почта России (До двери) (цена - '+infoDelivery.pochta.door.price[0]+' руб.)'));

                              }}
                              data-col={infoDelivery.pochta.door.price[0]} data-del={infoDelivery.pochta.door.deliver_type[0]}>
                            Почта России (До двери) (цена - {infoDelivery.pochta.door.price[0]} руб.) {infoDelivery.pochta.door.days}
                        </Cell>
                    }
                    {infoDelivery.pochta === undefined ? null : (infoDelivery.pochta.sklad === undefined ? null :
                            <Cell className='selectnew' value="pochta_pickup"
                                  onClick={() => {
                                      this.onTypeDelivery('pochta_pickup');
                                      this.onTypeDeliveryMethod('pochta_pickup');
                                      this.navigationBack();
                                      this.isAddress();
                                      this.props.dispatch(currencyProducts.setTextTypeDelivery('Почта России (До пункта выдачи) (цена - '+infoDelivery.pochta.sklad.price[0]+' руб.)'));
                                  }}
                                  data-col={infoDelivery.pochta.sklad.price[0]} data-del={infoDelivery.pochta.sklad.deliver_type[0]}>
                                Почта России (До пункта выдачи) (цена - {infoDelivery.pochta.sklad.price[0]} руб.) {infoDelivery.pochta.sklad.days}
                            </Cell>
                    )}
                    {infoDelivery.printbar === undefined ? null :
                        <Cell className='selectnew' value="printbar"
                              onClick={() => {
                                  this.onTypeDelivery('printbar');
                                  this.onTypeDeliveryMethod('printbar');
                                  this.navigationBack();
                                  this.isAddress();
                                  this.props.dispatch(currencyProducts.setTextTypeDelivery('Принтбар (До двери) (цена - '+infoDelivery.printbar.door.price[0]+' руб.)'));
                              }}
                              data-col={infoDelivery.printbar.door.price[0]} data-del={infoDelivery.printbar.door.deliver_type[0]}>
                            Принтбар (До двери) (цена - {infoDelivery.printbar.door.price[0]} руб.) {infoDelivery.printbar.door.days}
                        </Cell>
                    }
                    {infoDelivery.printbar === undefined ? null : (infoDelivery.printbar.sklad === undefined ? null :
                            <Cell className='selectnew' value="printbar_pickup"
                                  onClick={() => {
                                      this.onTypeDelivery('printbar_pickup');
                                      this.onTypeDeliveryMethod('printbar_pickup');
                                      this.navigationBack();
                                      this.isAddress();
                                      this.props.dispatch(currencyProducts.setTextTypeDelivery(' Принтбар (До пункта выдачи) (цена - '+infoDelivery.printbar.sklad.price[0]+' руб.)'));
                                  }}
                                  data-col={infoDelivery.printbar.sklad.price[0]} data-del={infoDelivery.printbar.sklad.deliver_type[0]}>
                                Принтбар (До пункта выдачи) (цена - {infoDelivery.printbar.sklad.price[0]} руб.) {infoDelivery.printbar.sklad.days}
                            </Cell>
                    )}
                </List>
            );
        }
    }
    navigationBack() {
        this.props.dispatch(goBack());
    }
}

function mapStateToProps(state) {
    return {
        userAddress: currencyItemsProducts.getUserAddress(state),

        infoDelivery: currencyItemsProducts.getDeliveryInfo(state),
        infoDelRecomended: currencyItemsProducts.getDeliveryRec(state),
        sumDelivery: currencyItemsProducts.getSumDelivery(state),

        infoDaData: currencyItemsProducts.getDaData(state),

        pickup: currencyItemsProducts.getPickup(state),
        Mpickup: currencyItemsProducts.getMPickup(state),
        dayDel: currencyItemsProducts.getDayDel(state),
    };
}

export default connect(mapStateToProps)(SelectM);

