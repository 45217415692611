export const PRODUCTS_FETCHED    = 'shop.PRODUCTS_FETCHED';
export const SET_ITEM_DATA       = 'shop.SET_ITEM_DATA';
export const ADD_TO_CART         = 'shop.ADD_TO_CART';
export const ADD_TO_SIZE         = 'shop.ADD_TO_SIZE';
export const ADD_TO_TYPE         = 'shop.ADD_TO_TYPE';
export const ADD_TO_QTY          = 'shop.ADD_TO_QTY';
export const DEL_TO_CART         = 'shop.DEL_TO_CART';
export const SET_POPOUT          = 'shop.SET_POPOUT';
export const SET_ORDER_ID        = 'shop.SET_ORDER_ID';
export const SET_DATA_SEND_ORDER = 'shop.SET_DATA_SEND_ORDER';
export const SET_DATA_SEND_VKPAY = 'shop.SET_DATA_SEND_VKPAY';
export const SET_USER_NAME       = 'shop.SET_USER_NAME';
export const SET_USER_EMAIL      = 'shop.SET_USER_EMAIL';
export const SET_USER_PHONE      = 'shop.SET_USER_PHONE';
export const SET_USER_ADDRESS    = 'shop.SET_USER_ADDRESS';
export const SET_DEL_ADDRESS     = 'shop.SET_DEL_ADDRESS';
export const SET_DA_DATA         = 'shop.SET_DA_DATA';
export const SET_POINT_DELIVERY  = 'shop.SET_POINT_DELIVERY';
export const SET_SUM_DELIVERY    = 'shop.SET_SUM_DELIVERY';
export const SET_DELIVERY_TYPE   = 'shop.SET_DELIVERY_TYPE';
//Доставка
export const SET_PICKUP  = 'shop.SET_PICKUP';
export const SET_MPICKUP = 'shop.SET_MPICKUP';
export const SET_DAY_DEL = 'shop.SET_DAY_DEL';
// Fake
export const SET_TEXT_TYPE_DELIVERY = 'shop.SET_TEXT_TYPE_DELIVERY';
export const SET_TEXT_TYPE_PUNKT    = 'shop.SET_TEXT_TYPE_PUNKT';
// verified
export const SET_VET_DEL_PUNKT      = 'shop.SET_VET_DEL_PUNKT';

export const PRODUCTS_FETCHED_TYPES = 'shop.PRODUCTS_FETCHED_TYPES';
