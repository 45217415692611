
import * as types from './actionTypes';
import ItemListProducts from '../../services/ItemListProducts';
import {ImmortalDB} from "immortal-db";

export function fetchItemListProducts() {
    return async (dispatch, getState) => {
        try {
            if (await ImmortalDB.get('product') === null) {
                //Первичный запрос к json
                const product = await ItemListProducts.getItemListProducts();
                //Рандомное перемешивание
                var currentIndex = product.length, temporaryValue, randomIndex;
                while (0 !== currentIndex) {

                    randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex -= 1;

                    temporaryValue = product[currentIndex];
                    product[currentIndex] = product[randomIndex];
                    product[randomIndex] = temporaryValue;
                }
                // END Рандомное перемешивание

                await ImmortalDB.set('product', JSON.stringify({product}));
                dispatch({type: types.PRODUCTS_FETCHED, product: product});
            } else {
                //Если есть запись в локалсторже
                const productLocal = JSON.parse(await ImmortalDB.get('product'));
                dispatch({type: types.PRODUCTS_FETCHED, product: productLocal.product});
                //console.log(productLocal)
            }
        } catch (error) {
            console.error(error);
        }
    };
}

export function setItemData(data) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_ITEM_DATA,
            items: data,
        });
    }
}

export function addItemCart(data) {
    return async (dispatch) => {
        dispatch({
            type: types.ADD_TO_CART,
            cart: data,
        });
    }
}
export function addItemCartSize(data) {
    return async (dispatch) => {
        dispatch({
            type: types.ADD_TO_SIZE,
            selectSizes: data,
        });
    }
}
export function addItemCartType(data) {
    return async (dispatch) => {
        dispatch({
            type: types.ADD_TO_TYPE,
            selectType: data,
        });
    }
}
export function addItemCartQty(data) {
    return async (dispatch) => {
        dispatch({
            type: types.ADD_TO_QTY,
            selectQty: data,
        });
    }
}
export function delItemCart(data) {
    return async (dispatch) => {
        dispatch({
            type: types.DEL_TO_CART,
            del: data,
        });
    }
}
export function setPopout(data) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_POPOUT,
            popout: data,
        });
    }
}
export function setOrderID(data) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_ORDER_ID,
            orderId: data.id_order,
        });
    }
}
export function setDataSendOrder(data) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_DATA_SEND_ORDER,
            dataSendOrder: data,
        });
    }
}
export function setUserName(user) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_USER_NAME,
            userName: user,
        });
    }
}
export function setUserEmail(email) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_USER_EMAIL,
            userEmail: email,
        });
    }
}
export function setUserPhone(phone) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_USER_PHONE,
            userPhone: phone,
        });
    }
}

export function setUserAddress(address) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_USER_ADDRESS,
            userAddress: address,
        });
    }
}

export function setDelAddress(address) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_DEL_ADDRESS,
            delAddress: address,
        });
    }
}

export function setDaData(dadata) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_DA_DATA,
            infoDaData: dadata,
        });
    }
}
export function setDelivery(delivery) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_POINT_DELIVERY,

            infoDelivery: delivery['delivers'],
            infoDelRecomended: delivery['recomended']
        });
    }
}
export function setDeliverySum(sum) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_SUM_DELIVERY,
            sumDelivery: sum
        });
    }
}
export function setDeliveryType(type) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_DELIVERY_TYPE,
            typeDelivery: type
        });
    }
}
//Доставка
export function setPickup(type) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_PICKUP,
            pickup: type
        });
    }
}
export function setMPickup(type) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_MPICKUP,
            Mpickup: type
        });
    }
}
export function setDayDel(type) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_DAY_DEL,
            dayDel: type
        });
    }
}
// Fake
export function setTextTypeDelivery(type) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_TEXT_TYPE_DELIVERY,
            textTypeDelivery: type
        });
    }
}
export function setTextTypePunkt(type) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_TEXT_TYPE_PUNKT,
            textTypeDeliveryPunkt: type
        });
    }
}
export function setVerDeliveryPunkt(type) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_VET_DEL_PUNKT,
            verifiedDeliveryPunkt: type
        });
    }
}

export function setDataSendVKPAY(data) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_DATA_SEND_VKPAY,
            VKPAYDataSend: data,
        });
    }
}
