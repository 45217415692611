import * as VKConnect from '@vkontakte/vkui-connect';
import * as types from './actionTypes';

const ID_APP = 6486733;
const API_VERSION = '5.85';

export function fetchAccessToken() {
    return async () => {
        VKConnect.send('VKWebAppGetAuthToken', {'app_id': ID_APP});
        VKConnect.send("VKWebAppResizeWindow", {"width": (window.innerWidth/2), "height": (window.innerHeight - 300),});
    }
}
export function sendVKPAY(data) {
    return async () => {
        VKConnect.send('VKWebAppOpenPayForm', {"app_id": ID_APP, "action": "pay-to-service", "params": data});
    }
}

export function fetchPhone() {
    return async (dispatch) => {
        VKConnect.send('VKWebAppGetPhoneNumber', {}, response => {
            dispatch({
                 type: types.VK_PHONE_FETCHED,
                    phoneNumber: response,
            });
        }, error => {
            console.log(error)
        });
    }
}

export function fetchEmail() {
    return async (dispatch) => {
        VKConnect.send('VKWebAppGetEmail', {}, response => {
            dispatch({
                 type: types.VK_EMAIL_FETCHED,
                    email: response,
            });
        }, error => {
            console.log(error)
        });
    }
}

export function fetchName() {
    return async (dispatch) => {
        VKConnect.send('VKWebAppGetUserInfo', {}, response => {
            dispatch({
                 type: types.VK_NAME_FETCHED,
                    name: response,
            });
        }, error => {
            console.log(error)
        });
    }
}
export function fetchAddressGeo() {
    return async (dispatch) => {
        VKConnect.send('VKWebAppGetGeodata', {}, response => {
            dispatch({
                 type: types.VK_GEO_FETCHED,
                    addressGeo: response,
            });
        }, error => {
            console.log(error)
        });
    }
}

export function fetchNotificationStatus(accessToken) {
    return async (dispatch) => {
        apiRequest('apps.isNotificationsAllowed', {}, accessToken, response => {
            dispatch({
                 type: types.VK_NOTIFICATION_STATUS_FETCHED,
                notificationStatus: response['is_allowed'],
            });
        }, error => {
            dispatch({type: types.VK_NOTIFICATION_STATUS_FAILED, error: error});
        });
    }
}

export function fetchGroupInvite(accessToken) {
    return async (dispatch) => {
        apiRequest('groups.isMember', {"group_id": 94185508}, accessToken, response => {
            dispatch({
                type: types.VK_GROUP_INVITE_FETCHED,
                groupInvite: response,
            });
        }, error => {
            console.log(error)
        });
    }
}

export function denyNotifications() {
    return async () => {
        VKConnect.send('VKWebAppDenyNotifications', {});
    }
}

export function allowNotifications() {
    return async () => {
        VKConnect.send('VKWebAppAllowNotifications', {});
    }
}

export function allowGroup() {
    return async () => {
        VKConnect.send("VKWebAppJoinGroup", {"group_id": 94185508});
    }
}

export function initApp() {
    return async (dispatch) => {
        VKConnect.subscribe(e => {
            let vkEvent = e.detail;
            if (!vkEvent) {
                console.error('invalid event', e);
                return;
            }

            let type = vkEvent['type'];
            let data = vkEvent['data'];

            switch (type) {
                case 'VKWebAppAllowNotificationsResult':
                    dispatch({
                        type: types.VK_NOTIFICATION_STATUS_FETCHED,
                        notificationStatus: true,
                    });
                    break;

                case 'VKWebAppDenyNotificationsResult':
                    dispatch({
                        type: types.VK_NOTIFICATION_STATUS_FETCHED,
                        notificationStatus: false,
                    });
                    break;

                case 'VKWebAppGetPhoneNumberResult':
                    dispatch({
                        type: types.VK_PHONE_FETCHED,
                        phoneNumber: data['phone_number'],
                        //phoneNumberStatus: false,
                    });
                    break;
                //case 'VKWebAppGetPhoneNumberFailed':
                //    dispatch({
                //        type: types.VK_PHONE_FETCHED,
                //        phoneNumber: '+7',
                //        phoneNumberStatus: true,
                //    });
                //    break;
                case 'VKWebAppGetEmailResult':
                    dispatch({
                        type: types.VK_EMAIL_FETCHED,
                        email: data['email'],
                        //emailStatus: false
                    });
                    break;
                //case 'VKWebAppGetEmailFailed':
                //    dispatch({
                //        type: types.VK_EMAIL_FETCHED,
                //        email: '@mail.ru',
                //        emailStatus: true
                //    });
                //    break;


                case 'VKWebAppGetUserInfoResult':
                    dispatch({
                        type: types.VK_NAME_FETCHED,
                        name: data['first_name'] + ' ' + data['last_name']
                    });
                    break;

                case 'VKWebAppGeodataResult':
                    dispatch({
                        type: types.VK_GEO_FETCHED,
                        addressGeo: [data['lat'],data['long']]
                    });
                    break;

                case 'VKWebAppAccessTokenReceived':
                    dispatch({
                        type: types.VK_GET_ACCESS_TOKEN_FETCHED,
                        accessToken: data['access_token']
                    });
                    break;

                case 'VKWebAppUpdateInsets':
                    dispatch({
                        type: types.VK_INSETS_FETCHED,
                        insets: data.insets
                    });
                    break;

                default:
                //nop;
            }
        });

        VKConnect.send('VKWebAppInit', {'no_toolbar': true});
    }
}
function apiRequest(method, params = {}, accessToken = '', successCallback = undefined, errorCallback = undefined) {
    let requestId = getNewRequestId();
    if (successCallback !== undefined || errorCallback !== undefined) {
        let clb = function callback(e) {
            let vkEvent = e.detail;
            if (!vkEvent) {
                console.error('invalid event', e);
                return;
            }

            let type = vkEvent['type'];
            let data = vkEvent['data'];

            let found = false;
            if ('VKWebAppCallAPIMethodResult' === type && data['request_id'] === requestId) {
                if (successCallback !== undefined) {
                    successCallback(data['response']);
                }

                found = true;
            } else if ('VKWebAppCallAPIMethodFailed' === type && data['request_id'] === requestId) {
                if (errorCallback !== undefined) {
                    errorCallback(data);
                }

                found = true;
            }

            if (found) {
                VKConnect.unsubscribe(clb);
            }

        };

        VKConnect.subscribe(clb);
    }

    params['access_token'] = accessToken;

    if (params['v'] === undefined) {
        params['v'] = API_VERSION;
    }


    VKConnect.send('VKWebAppCallAPIMethod', {
        'method': method,
        'params': params,
        'request_id': requestId
    });
}

function getNewRequestId() {
    return (Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)).toString();
}
