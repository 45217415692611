import React, {Component} from 'react';
import {connect} from 'react-redux';
import Header from '@vkontakte/vkui/dist/components/Header/Header';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Search from '@vkontakte/vkui/dist/components/Search/Search';
import Tabs from '@vkontakte/vkui/dist/components/Tabs/Tabs';
import TabsItem from '@vkontakte/vkui/dist/components/TabsItem/TabsItem';
import HorizontalScroll from '@vkontakte/vkui/dist/components/HorizontalScroll/HorizontalScroll';
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import List from '@vkontakte/vkui/dist/components/List/List';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import * as currencyItemsProducts from '../store/shop/reducer';
import ShowMore from '@tedconf/react-show-more';
import {push} from "connected-react-router";
import * as currencyProducts from "../store/shop/actions";
import HorizontalScrollDesktop from 'react-scroll-horizontal';
import ScrollLock from 'react-scrolllock';
import isMobile from 'react-device-detect';

import { saveState, getState } from '../services/storage';

class CurrencyRateDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lockScroll: false,
            search: '',
            sexItem: [
                {title: "Мужские", name: 'Мужчинам'},
                {title: "Женские", name: 'Женщинам'}
            ],
            typeSelectedIndex: getState('typeSelectedIndex') || '',
            typeSelectedName: getState('typeSelectedName') || '',
            typeSelectedCollIndex: getState('typeSelectedCollIndex') || '',
            typeSelectedCollection: getState('typeSelectedCollection') || '',
            typeSex: getState('typeSex') || 'Мужчинам',
            typeSexName: getState('typeSexName') || 'Мужские',
            typeShowMoreIndex: getState('typeShowMoreIndex') || 6,
            itemNew: this.props.product,
        };
        this.onChangeSearch = this.onChangeSearch.bind(this);
    }


    onChangeSearch (search) {this.setState({ search });}
    itemTypeMain(tags) {
        let results = [];

        tags.forEach(function (value) {
            value = value.trim();

            if (results.indexOf(value) === -1) {
                results.push(value);
            }
        });

        return results;
    }
    // Получаем типы
    get itemDataTypes () {
        let results = this.itemTypeMain(this.props.product.map((item)=>{return item.type}));
        let ordering = {"Футболки":1, "Свитшоты":2, "Худи":3, "Лонгсливы":4, "Майки":5, default:1000},
            items = results.sort(function (a, b) {
                return (ordering[a] || ordering.default) - (ordering[b] || ordering.default);
            });
        return items;
    }
    // Получаем коллекции
    get itemDataCollections () {
        let results = this.itemTypeMain(this.props.product.map((item) =>{return item.collection}));
        return results
    }
    // Получаем товары фильтруя по поиску
    get itemData () {
        const search = this.state.search.toLowerCase();
        return this.itemDataCollection.filter(({name,type,collection}) => name.toLowerCase().includes(search) || type.toLowerCase().includes(search)|| collection.toLowerCase().includes(search));
    }
    //Поиск по типу
    get itemDataType () {
        const search = this.state.typeSelectedName.toLowerCase();
        return this.itemDataSex.filter(({type}) => type.toLowerCase().indexOf(search)  !== -1);
    }
    //Поиск по коллекции
    get itemDataCollection () {
        const search = this.state.typeSelectedCollection.toLowerCase();
        return this.itemDataType.filter(({collection}) => collection.toLowerCase().indexOf(search)  !== -1 );
    }

    //Поиск по полу
    get itemDataSex () {
        const search = this.state.typeSex.toLowerCase();
        return this.props.product.filter(({sex}) => sex.toLowerCase().indexOf(search)  !== -1 );
    }
    // END Работа поиска

    get itemDataPopular () {
        const search = this.state.typeSex.toLowerCase();
        return this.props.product.filter(({sex}) => sex.toLowerCase().indexOf(search)  !== -1 );
    }

    get itemDataNew () {
        const search = this.state.typeSex.toLowerCase();
        return [].concat(this.props.product).sort((a,b) => {return new Date(b.date_update).getTime() - new Date(a.date_update).getTime()}).filter(({sex}) => sex.toLowerCase().indexOf(search) !== -1 );
    }

    horizontalScrolling(tag) {
        let Horizontal = <HorizontalScroll>{tag}</HorizontalScroll>;
        let HorizontalDesktop = <HorizontalScrollDesktop>{tag}</HorizontalScrollDesktop>;
        let mobile = isMobile !== true ? HorizontalDesktop :Horizontal;
        return mobile
    }
    LockScrolling(lock) {
        let lockTRUE = <ScrollLock isActive={this.state.lockScroll}>{lock}</ScrollLock>;
        let lockFALSE = {lock};
        let mobile = isMobile !== true ? lockTRUE : lockFALSE;
        return mobile
    }

    render() {
        let horizontalScrollingStyle = isMobile !== true ? {height:"32px"} : null;
        if (!this.props.product) {
            return (<Div align="center">Список товаров загружается...</Div>);
        }

        return (
            <React.Fragment>
                    <Group>
                        <Tabs theme="light">
                            {this.state.sexItem.map((item, index) => (
                                <TabsItem
                                    key={index}
                                    onClick={() => {
                                        this.setState({typeSex: item.name, typeSexName: item.title});
                                        saveState('typeSex', item.name);
                                        saveState('typeSexName', item.title);
                                    }}
                                    selected={this.state.typeSex === item.name}>
                                    {item.title}
                                </TabsItem>
                            ))}
                        </Tabs>
                    </Group>
                    <Group>
                        <Tabs type="buttons" style={horizontalScrollingStyle}
                              onMouseEnter={() => this.setState({lockScroll: true})}
                              onMouseLeave={() => this.setState({lockScroll: false})}>
                            {this.horizontalScrolling(
                                <React.Fragment>
                                    <TabsItem
                                        onClick={() => {
                                            this.setState({typeSelectedIndex: '', typeSelectedName: ''});
                                            saveState('typeSelectedIndex', '');
                                            saveState('typeSelectedName', '');
                                        }}
                                        selected={this.state.typeSelectedIndex === ''}>
                                        Все
                                    </TabsItem>
                                    {this.itemDataTypes.map((item, index) => (
                                        <TabsItem
                                            key={index}
                                            onClick={() => {
                                                this.setState({
                                                    typeSelectedIndex: index,
                                                    typeSelectedName: item
                                                });
                                                saveState('typeSelectedIndex', index);
                                                saveState('typeSelectedName', item);
                                            }}
                                            selected={this.state.typeSelectedIndex === index}>
                                            {item}
                                        </TabsItem>
                                    ))}
                                </React.Fragment>
                            )}
                        </Tabs>
                    </Group>
                    <Group>
                        <Tabs type="buttons" style={horizontalScrollingStyle}
                              onMouseEnter={() => this.setState({lockScroll: true})}
                              onMouseLeave={() => this.setState({lockScroll: false})}>
                            {this.horizontalScrolling(
                                <React.Fragment>
                                    <TabsItem
                                        onClick={() => {
                                            this.setState({
                                                typeSelectedCollIndex: '',
                                                typeSelectedCollection: ''
                                            });
                                            saveState('typeSelectedCollIndex', '');
                                            saveState('typeSelectedCollection', '');
                                        }}
                                        selected={this.state.typeSelectedCollIndex === ''}>
                                        Все коллекции
                                    </TabsItem>
                                    {this.itemDataCollections.map((item, index) => (
                                        <TabsItem
                                            key={index}
                                            onClick={() => {
                                                this.setState({
                                                    typeSelectedCollIndex: index,
                                                    typeSelectedCollection: item
                                                });
                                                saveState('typeSelectedCollIndex', index);
                                                saveState('typeSelectedCollection', item);
                                            }}
                                            selected={this.state.typeSelectedCollIndex === index}>
                                            {item}
                                        </TabsItem>
                                    ))}
                                </React.Fragment>
                            )}
                        </Tabs>
                    </Group>
                    {this.renderNewItems()}
                    {this.renderPopularItems()}
                <Group title={(this.state.typeSelectedName === '' ? 'Все' : this.state.typeSelectedName) + ' (' + this.state.typeSexName + ')'+ (this.state.typeSelectedCollection === '' ? '' : ' - '+ this.state.typeSelectedCollection) + ' - ' + this.itemData.length}>
                    <Search  value={this.state.search} theme="default" placeholder="Поиск товара" onChange={this.onChangeSearch}/>
                    {this.itemData.length > 0 ?
                        <ShowMore items={this.itemData} by={this.state.typeShowMoreIndex} >
                            {({current,onMore}) => (
                                <List>
                                    <div
                                        ref={container => (this.container = container)}
                                        onScroll={() => {
                                            const {
                                                      scrollTop,
                                                  } = this.container;

                                            if (scrollTop) {
                                                if (typeof onMore === "function") onMore();
                                            }
                                        }}>

                                    {current.map((item,index) => (
                                        <Cell key={index} expandable
                                                 onClick={() => {
                                                     this.props.dispatch(currencyProducts.setItemData(item));
                                                     this.openItem(this);
                                                 }}
                                                 photo={item.image.front.thumb}
                                                 description={<Div className="iteminfo-description" style={{padding: '10px 0', color: '#C4C4C4',userSelect: 'none', pointerEvents: 'none'}}>{item.type}</Div>}
                                                 bottomContent={<Div style={{padding: 0, fontWeight:'500',userSelect: 'none'}}>{item.price.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')} &#8381; {item.price_old === '0' ? null : <span className="iteminfo-price-old-mini">{item.price_old.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')} &#8381;</span>}</Div>}
                                                 before={<Avatar type="image" alt={item.name} src={item.image.front.thumb} size={80}/>}
                                                 size="l"
                                        >
                                            {item.name}
                                        </Cell>
                                    ))}
                                        {this.itemData.length > this.state.typeShowMoreIndex ?
                                            <Div>
                                                {!onMore === true ? '' : <Button level="sell" onClick={() => {
                                                    saveState('typeShowMoreIndex', (this.state.typeShowMoreIndex)+ 6)
                                                    if (!!onMore) onMore();
                                                }} size="xl">Показать еще</Button>
                                                }
                                            </Div> : ''}
                                    </div>
                                </List>
                            )}
                        </ShowMore> :
                        <Cell>
                            <Div align="center">Товар не найден</Div>
                        </Cell>
                    }
                </Group>
            </React.Fragment>
        );
    }
    renderNewItems() {
        const itemStyle = {
            //flexShrink: 0,
            minWidth: 150,
            maxWidth: 150,
            //display: 'flex',
            //flexDirection: 'column',
            //alignItems: 'center',
            fontSize: 12,
            paddingLeft: 0,
            paddingRight: 0,
        };
        const itemStyleName = {
            fontWeight: 400,
            lineHeight: 1.2,
            fontSize: 14,
            color: 'black',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
        };
        const itemStyleSex  = {
            lineHeight: 1.2,
            fontSize: 12,
            color: '#C4C4C4',
            width: '100%'
        };
        if (this.state.typeSelectedCollIndex === '' && this.state.typeSelectedIndex === '') {
            return (
                <Group style={{ paddingBottom: 8 }}>
                    <Header level="2">Новые</Header>
                    <div style={{height: "150px"}}>
                    {this.horizontalScrolling(
                    <div style={{ display: 'flex'}}
                         onMouseEnter={() => this.setState({lockScroll: true})}
                         onMouseLeave={() => this.setState({lockScroll: false})}>
                         {this.itemDataNew.slice(0, 16).map((item,index) => (
                                <Cell key={index} style={itemStyle}
                                      onClick={() => {
                                          this.props.dispatch(currencyProducts.setItemData(item));
                                          this.openItem(this)
                                      }}
                                >
                                    <Avatar type="image" alt={item.name} src={item.image.front.thumb} size={80} style={{
                                        marginBottom: 4,
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}/>
                                    <div style={itemStyleName}>{item.name}</div>
                                    <div style={itemStyleSex}>{item.sex === 'Мужчинам' ? 'Мужская' : 'Женская'} {item.type.toLowerCase()}</div>
                                    <div style={itemStyleSex}> {item.collection}</div>
                                </Cell>
                            ))}
                        </div>
                    )}
                    </div>
                </Group>
            );
        }
    }
    renderPopularItems() {
        const itemStyle = {
            //flexShrink: 0,
            minWidth: 150,
            maxWidth: 150,
            //display: 'flex',
            //flexDirection: 'column',
            //alignItems: 'center',
            fontSize: 12,
            paddingLeft: 0,
            paddingRight: 0,
        };
        const itemStyleName = {
            fontWeight: 400,
            lineHeight: 1.2,
            fontSize: 14,
            color: 'black',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '80%',
        };
        const itemStyleSex  = {
            lineHeight: 1.2,
            fontSize: 12,
            color: '#C4C4C4',
            width: '80%'
        };
        if (this.state.typeSelectedCollIndex === '' && this.state.typeSelectedIndex === '') {
            return (
                <Group style={{ paddingBottom: 8 }}>
                    <Header level="2">Популярные</Header>
                    <div style={{height: "150px"}}>
                        {this.horizontalScrolling(
                            <div style={{height: "150px", display: 'flex'}}
                                 onMouseEnter={() => this.setState({lockScroll: true})}
                                 onMouseLeave={() => this.setState({lockScroll: false})}>
                                    {this.itemDataPopular.slice(0, 16).map((item, index) => (
                                        <Cell key={index} style={itemStyle}
                                              onClick={() => {
                                                  this.props.dispatch(currencyProducts.setItemData(item));
                                                  this.openItem(this);
                                              }}
                                        >
                                            <Avatar type="image" alt={item.name} src={item.image.front.thumb} size={80} style={{
                                                marginBottom: 4,
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}/>
                                            <div style={itemStyleName}>{item.name}</div>
                                            <div style={itemStyleSex}>{item.sex === 'Мужчинам' ? 'Мужская' : 'Женская'} {item.type.toLowerCase()}</div>
                                            <div style={itemStyleSex}> {item.collection}</div>
                                        </Cell>
                                    ))}
                            </div>
                        )}

                    </div>
                </Group>
            );
        }
    }
    openItem() {
        this.props.dispatch(push('/product'));
    }
}

function mapStateToProps(state) {
    return {
        product: currencyItemsProducts.getItemsProducts(state)
    };
}

export default connect(mapStateToProps)(CurrencyRateDashboard);
