import React, {Component} from 'react';
import { platform, IOS } from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import HeaderButton from '@vkontakte/vkui/dist/components/HeaderButton/HeaderButton';
import {connect} from 'react-redux';
import {goBack} from 'connected-react-router';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import * as currencyItemsProducts from "../store/shop/reducer";

class Fabric extends Component {
    render() {
        const osname = platform();

        return (
                <Panel id={this.props.id}>
                    <PanelHeader
                        left={<HeaderButton onClick={this.navigationBack.bind(this)}>{osname === IOS ?
                            <Icon28ChevronBack/> : <Icon24Back/>}</HeaderButton>}
                    >
                        Тип ткани
                    </PanelHeader>
                    {this.infoСlothing()}
                    <Group title="Обычная ткань" description="Состав: 100% микрофибра">
                        <Div>
                            Интерлок 140 гр/кв.м (микрофибра) – материал достаточно мягок и приятен на ощупь, не растягивается, не садится, не выгорает, гипоаллергенен, хорошо отстирывается и не теряет при этом своих насыщенных цветов, обладает высокой воздухопроницаемостью, хорошим охлаждающим эффектом, быстро сохнет. Идеально подходит для занятия спортом.
                        </Div>
                    </Group>
                    <Group title="Премиум ткань" description="Состав: 97% микрофибра, 3% эластана">
                        <Div>
                            Джерси 175 гр./кв.м - один из самых востребованных видов трикотажной ткани. <br/>
                            Известность материалу принесла именитая француженка Коко Шанель и в 1916 году представила парижскому модному обществу свою новую коллекцию. <br/>
                            Изделия из данной ткани плотнее, более мягкие, легкие, практически не чувствуются на теле. <br/>
                            Вещи из нее можно смело брать с собой в поездки, в которых вы не планируете тратить время на глажку одежды. Цвет футболок не померкнет спустя множество стирок.
                        </Div>
                    </Group>
                    <Group title="Люкс ткань" description="Состав: 97% микрофибра, 3% эластана">
                        <Div>
                            Кулирка 210 гр/м2 - Для обладателей чувствительной кожи и ценителей комфорта – идеальная ткань. Она впитывает влагу и пропускает воздух, практична и гипоаллергена, прочна и долговечна за счет более качественной вязки с добавлением эластана (мононити используются для придания ткани биэластичности). Специальная обработка антипилингом для придания мягкости
                        </Div>
                    </Group>
                </Panel>
        );
    }

    infoСlothing(type) {
        if (type === 'Футболки') {
            return (
                <Group title="Футболки">
                    <Div>
                        Каждая вещь изготавливается в ручную для вас. Теперь мы предлагаем вам еще
                        больше возможностей для индивидуализации вашей новой футболки.
                    </Div>
                </Group>
            );
        } else if (type === 'Cвитшоты') {
            return (
                <Group title="Cвитшот">
                    <Div>
                        Каждая вещь изготавливается в ручную для вас. Теперь мы предлагаем вам еще
                        больше возможностей для индивидуализации вашего нового свитшота.
                    </Div>
                </Group>
            );
        } else if (type === 'Худи') {
            return (
                <Group title="Худи">
                    <Div>
                        Каждая вещь изготавливается в ручную для вас. Теперь мы предлагаем вам еще
                        больше возможностей для индивидуализации вашего нового худи.
                    </Div>
                </Group>
            );
        } else if (type === 'Лонгсливы') {
            return (
                <Group title="Лонгслив">
                    <Div>
                        Каждая вещь изготавливается в ручную для вас. Теперь мы предлагаем вам еще
                        больше возможностей для индивидуализации вашего нового лонгслива.
                    </Div>
                </Group>
            );
        }
    }
    navigationBack() {
        this.props.dispatch(goBack());
    }
}

function mapStateToProps(state) {
    return {
        items:currencyItemsProducts.getItemData(state),
        product: currencyItemsProducts.getItemsProducts(state)
    };
}

export default connect(mapStateToProps)(Fabric);

