class ItemListProducts {
    async getItemListProducts() {
        return fetch('/items.json')
            .then(function (response) {
                return response.json();
            })
    }
}

export default new ItemListProducts();
